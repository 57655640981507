import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getUserGroupsByTenantID = (tenantID: number) => {
  return api.get(
    `${SERVICE_BASE_URLS.ACCOUNTS}/user_groups/tenant/${tenantID}`
  );
};

const getFamilyUserGroupsByTenantID = (tenantID: number, options: any) => {
  return api.get(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-user-groups/tenant/${tenantID}`,
    options
  );
};

const updateFamilyUserGroups = (user_group_id: string, options: any, data: any) => {
  return api.put(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-user-group/${user_group_id}`,
    data,
    options
  );
}

const getUserGroupsByTenantIds = (tenantIdsParams: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/user_groups/by_tenant_ids?${tenantIdsParams}`);
};


const createUserGroup = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/user_groups`, data);
};

const updateUserGroup = (user_group_id: string, tenant_id: number, data: any) => {
  return api.put(
    `${SERVICE_BASE_URLS.ACCOUNTS}/user_groups/${user_group_id}/tenants/${tenant_id}`,
    data
  );
};

const getUserGroups = () => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/user_groups`);
};

const UserGroupService = {
  getUserGroupsByTenantID,
  createUserGroup,
  updateUserGroup,
  getUserGroups,
  getFamilyUserGroupsByTenantID,
  updateFamilyUserGroups,
  getUserGroupsByTenantIds,
};

export default UserGroupService;
