import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { FormInput } from "../../components/FormInput";
import { useFieldArray, useForm } from "react-hook-form";
import { FormCheckbox } from "../../components/FormCheckbox";
import FamilyService from "../../services/famiy.service";
import FamilyDeploymentService from "../../services/family-deployment.service";
import { FormAutocomplete } from "../../components/FormAutoComplete";
import Typography from "@mui/material/Typography";
import { FormDatePicker } from "../../components/FormDatePicker";
import moment from "moment";
import DialogActions from "@mui/material/DialogActions";
import FormChipGroup from "../../components/FormChipGroup";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Ably from 'ably'
import ChannelMessage from "../../components/ChannelMessage";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { AblyProvider, ChannelProvider } from "ably/react";
import DeploymentService from "../../services/deployment.service";
import { constructDeployementDetail } from "../../utils/utils";
import useNotifications from "../../components/NotificationProvider";
import useLoader from "../../components/LoaderProvider";
interface FormState {
  visible: boolean;
  heading: string;
}


interface ChildComponentProps {
  selectedEnv: any;
  ablyClient: Ably.Realtime | undefined;
  inProgressDeployment: any;
}


const Deployments = ({ selectedEnv, ablyClient, inProgressDeployment }: ChildComponentProps) => {
  const [chipList, setChipList] = useState<any>({});
  const [channel, setChannel] = useState<string>("");
  const[disableSaveBtn , setDisableSaveBtn] = useState<boolean>(false)
  const defaultModelValue = {
    family: "",
    process: "",
    ensemble: "",
    // is_active: "",
    // is_realtime: "",
    // start_time: "",
    // end_time: "",
  };
  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      model_list: [defaultModelValue],
    },
  });

  const { setLoading } = useLoader();
  const { setNotification } = useNotifications();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "model_list",
  });

  const [families, setFamilies] = useState<any>([]);
  const [processes, setProcesses] = useState<any>([]);
  const [ensembles, setEnsembles] = useState<any>([]);
  const [openDeploymentForm, setOpenDeploymentForm] = useState<FormState>({
    visible: false,
    heading: "",
  });

  const handleDeploymentFromClick = (visible: boolean, heading: string) => {
    reset({ "model_list": [defaultModelValue] })
    setOpenDeploymentForm({ visible, heading });
  };

  const onSubmit = async (values: any) => {
    try {
      setLoading(true)
      setDisableSaveBtn(true)
      const payload = constructDeployementDetail(values);
      
      const options = {
        headers: { "env-id": selectedEnv.environment_id}
      }
      await DeploymentService.createDeployment(options,payload)
      setChipList({});
      setOpenDeploymentForm({ visible: false, heading: '' })
      setNotification({
        message: `Deployment created successfully`,
        open: true,
      });
      setOpenDeploymentForm({ visible: false, heading: '' })

    }catch(e){
      console.error("Error", e);
      setNotification({ message: "Error", open: true, type: "error" });
    } finally {
      setDisableSaveBtn(false)
      setLoading(false)
    }
    
  };

  const handleOnClose = () => {
    setValue("model_list", [defaultModelValue]);
    setOpenDeploymentForm((prevState: FormState) => ({
      ...prevState,
      visible: false,
    }));
    setChipList({});
    setEnsembles([]);
  };

  const getFamilies = async () => {
    try {
      const options = {
        headers: { "env-id": selectedEnv.environment_id },
      };
      const familiesResp = await FamilyService.getFamilies(options);
      const familyListResponse = familiesResp.data.data;
      const newFamilyListResponse = familyListResponse.map((obj: any) => ({
        ...obj,
        family_name: obj.name,
      }));
      setFamilies(newFamilyListResponse);
    } catch (e) {
      console.error("Error", e);
    }
  };

  const getProcesses = async () => {
    try {
      const options = {
        headers: { "env-id": selectedEnv.environment_id },
      };
      const processResp = await FamilyDeploymentService.getProcesses(options);
      setProcesses(processResp.data.data);
    } catch (e) {
      console.error("Error", e);
    }
  };

  const getEnsembleListBySearchText = async (searchText: string, index?: number) => {
    try {
      setLoading(true)
      if (searchText.trim().length >= 2) {
        const selectedProcess = getValues(`model_list[${index}].process`);
        const options = {
          headers: { "env-id": selectedEnv.environment_id },
        };
        const params = {
          searchText: searchText,
          processId: selectedProcess?.process_id,
        };
        const response = await FamilyDeploymentService.getEnsembleListBySearch(
          options,
          params
        );
        setEnsembles(response.data.data);
      }
    } catch (e) {
      console.error("Error", e);
    }finally{
      setLoading(false)
    }
  };

  const getEnsembleListByProcessId = async (processId: number) => {
    try {
      setLoading(true)
      if (processId) {
        const options = {
          headers: { "env-id": selectedEnv.environment_id },
        };
        const params = {
          processId: processId,
        };
        const response = await FamilyDeploymentService.getEnsembleListBySearch(
          options,
          params
        );
        setEnsembles(response.data.data);
      }
    } catch (e) {
      console.error("Error", e);
    } finally {
      setLoading(false)
    }
  };

  const handleProductEnsembleAdd = (index: number) => {
    const ensemble = getValues(`model_list[${index}].ensemble`);
    const process = getValues(`model_list[${index}].process`);
    if (ensemble && process) {
      const newItemKeyId = `${process.process_id}-${ensemble.ensemble_id}`;
      const exists =
        chipList[index] &&
        chipList[index].some((item: any) => item.key_id === newItemKeyId);

      if (!exists) {
        const newChipList = {
          ...ensemble,
          ...process,
          key_id: newItemKeyId,
        };

        setChipList((prevState: any) => {
          if (prevState[index]) {
            return {
              ...prevState,
              [index]: [...prevState[index], newChipList],
            };
          } else {
            return { ...prevState, [index]: [newChipList] };
          }
        });
      }

      setValue(`model_list[${index}].ensemble`, "");
      setValue(`model_list[${index}].process`, "");
    }
  };

  useEffect(() => {
    Object.keys(chipList).forEach((index) => {
      setValue(`model_list[${index}].process_ensembles`, chipList[index]);
    });
  }, [chipList, setValue]);

  const handleProductEnsembleDelete = (index: number, key_id: string) => {
    const newChipList =
      chipList[index]?.filter((chip: any) => chip.key_id !== key_id) || [];
    setChipList((prevState: any) => ({
      ...prevState,
      [index]: newChipList,
    }));
  };

  const setDeploymentChannel = async () => {
    try {
      const options = {
        headers: {"env-id": selectedEnv.environment_id },
      };
      const response = await DeploymentService.getDeploymentChannel(options)
      const channelName = response.data.name
      const channel = `cr-persist:${channelName}`
      setChannel(channel);
    } catch (e) {
      console.error("Error", e);
    }
  };


  useEffect(() => {
    if (selectedEnv) {
      getFamilies();
      getProcesses();
      setDeploymentChannel()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnv]);

  return (
    <>
      <Card sx={{ backgroundColor: "#2A2B31", maxWidth: "1550px" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <CardHeader
            action={[
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => handleDeploymentFromClick(true, "Create")}
                disabled={!selectedEnv}
              >
                Create
              </Button>,
            ]}
          />
          <TableContainer component={Paper}></TableContainer>
          {/* Ably Channel message display container */}
          {ablyClient && channel ? (
          <AblyProvider client={ablyClient}>
            <ChannelProvider
              channelName={channel}
              options={{ params: { rewind: "10" } }}
            >
              <ChannelMessage selectedEnv={selectedEnv} channelName={channel} />
            </ChannelProvider>
          </AblyProvider>
          ) : (
          <Box
            sx={{
              backgroundColor: "#2A2B31",
              maxWidth: "1550px",
              padding: "10px",
            }}
          >
            {!ablyClient
              ? "The Ably client has not been initialized yet..."
              : "Environment has not been selected yet..."}
          </Box>
          )}
        </CardContent>
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          open={openDeploymentForm.visible}
        >
          <DialogTitle>
            {`${openDeploymentForm.heading} Deployment`}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box component="main">
              <form>
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={4}>
                      <FormInput
                        name="endpoint_id"
                        type="string"
                        id="endpoint_id"
                        label="Endpoint ID"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="compute_name"
                        type="string"
                        id="compute_name"
                        label="Compute Name"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormInput
                        name="instance_type"
                        type="string"
                        id="instance_type"
                        label="Instance Type"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormInput
                        name="auto_scale_max_replicas"
                        type="number"
                        id="auto_scale_max_replicas"
                        label="Max Replicas Auto Scale"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="azure_ml_block_name"
                        type="string"
                        id="azure_ml_block_name"
                        label="Azure ML Block Name"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        name="prefect_db_block"
                        type="string"
                        id="prefect_db_block"
                        label="Prefect DB Block"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInput
                        name="cpu_cores"
                        type="number"
                        id="cpu_cores"
                        label="CPU Cores"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInput
                        name="memory_gb"
                        type="decimal"
                        id="memory_gb"
                        label="Memory GB"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormInput
                        name="num_replicas"
                        type="number"
                        id="num_replicas"
                        label="No of Replicas"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormInput
                        name="schema_system"
                        type="number"
                        id="schema_system"
                        label="Schema System"
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormCheckbox
                        name="enable_app_insights"
                        id="enable_app_insights"
                        label="Enable App Insights"
                        rules={{}}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>

                    {fields.length === 0 ? (
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setValue("model_list", [defaultModelValue]);
                          }}
                        >
                          Add Model List
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            mt={2}
                            color="#888"
                            sx={{ fontWeight: "bold" }}
                          >
                            Model List
                          </Typography>
                        </Box>
                      </Grid>
                    )}

                    {fields.map((item, index) => (
                      <>
                        <Grid container spacing={3}>
                          {/* Model data container */}
                          <Grid item xs={12} md={10}>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                bgcolor: "#161b22",
                                padding: "10px 0 10px 0",
                                marginTop: "10px",
                                marginLeft: "5px",
                              }}
                            >
                              <Grid item xs={4}>
                                <FormAutocomplete
                                  name={`model_list[${index}].family`}
                                  id={`model_list[${index}].family`}
                                  label="Family"
                                  options={families}
                                  labelKey="family_name"
                                  valueKey="family_id"
                                  rules={{
                                    required: "This field is required",
                                  }}
                                  control={control}
                                  register={register}
                                  errors={errors}
                                />
                              </Grid>
                              <Grid item xs={7}>
                                <FormChipGroup
                                  name={`model_list[${index}].process_ensembles`}
                                  id={`model_list[${index}].process_ensembles`}
                                  label="Process-ensembles"
                                  control={control}
                                  register={register}
                                  errors={errors}
                                  sx={{
                                    minHeight: "56px",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    listStyle: "none",
                                    p: 0.5,
                                    m: 0,
                                    background: "transparent",
                                    borderRadius: "4px",
                                    border: "1px solid #bdb8ba",
                                  }}
                                  chips={chipList[index] ? chipList[index] : []}
                                  index={index}
                                  handleChipDelete={handleProductEnsembleDelete}
                                />
                              </Grid>
                              {/* empty grid for create space in left side */}
                              <Grid item xs={4}></Grid>
                              <Grid item xs={3.5} sx={{}}>
                                <FormAutocomplete
                                  name={`model_list[${index}].process`}
                                  id={`model_list[${index}].process`}
                                  label="Process"
                                  options={processes}
                                  labelKey="process_name"
                                  valueKey="process_id"
                                  handleOnChange={(value) => {
                                    if (value) {
                                      setValue(
                                        `model_list[${index}].ensemble`,
                                        ""
                                      );
                                      getEnsembleListByProcessId(
                                        value.process_id
                                      );
                                    }
                                  }}
                                  control={control}
                                  register={register}
                                  errors={errors}
                                />
                              </Grid>
                              <Grid item xs={3.5}>
                                <FormAutocomplete
                                  name={`model_list[${index}].ensemble`}
                                  id={`model_list[${index}].ensemble`}
                                  label="Ensemble"
                                  options={ensembles}
                                  labelKey="ensemble_name"
                                  valueKey="ensemble_id"
                                  index={index}
                                  disableFilter={true}
                                  getUniqueOption={true}
                                  handleOnInputChange={
                                    getEnsembleListBySearchText
                                  }
                                  handleOnChange={(value) => {
                                    value &&
                                      setValue(`model_list[${index}].process`, {
                                        process_id: value.process_id,
                                        process_name: value.process_name,
                                      });
                                  }}
                                  control={control}
                                  register={register}
                                  errors={errors}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <AddCircleOutlineRoundedIcon
                                  fontSize="large"
                                  sx={{ cursor: "pointer", marginTop: "5px" }}
                                  onClick={() =>
                                    handleProductEnsembleAdd(index)
                                  }
                                ></AddCircleOutlineRoundedIcon>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* button container */}
                          <Grid item xs={12} md={2}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={2}
                              sx={{ padding: "10px" }}
                            >
                              <Button
                                onClick={() => {
                                  setChipList((prevState: any) => {
                                    const { [index]: _, ...newState } =
                                      prevState;
                                    return newState;
                                  });
                                  remove(index);
                                }}
                                sx={{ flexGrow: 0.5 }}
                              >
                                Delete
                              </Button>
                              <Button
                                variant="contained"
                                sx={{ flexGrow: 0.5 }}
                                onClick={() => {
                                  setChipList((prevState: any) => ({
                                    ...prevState,
                                    [fields.length]: chipList[index],
                                  }));
                                  append(getValues(`model_list[${index}]`));
                                }}
                              >
                                Clone
                              </Button>
                              {index === fields.length - 1 && (
                                <Button
                                  variant="contained"
                                  onClick={() => append(defaultModelValue)}
                                >
                                  Add
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                    <Grid item xs={4} style={{ display: "none" }}>
                      <FormDatePicker
                        name="start_time"
                        id="start_time"
                        label="Start time"
                        type="datetime-local"
                        defaultValue={moment()
                          .seconds(0)
                          .format("yyyy-MM-DDTHH:mm:ss")}
                        rules={{
                          required: "This field is required",
                          validate: {
                            greaterThanEndDate: (v: string) =>
                              !getValues("end_time") ||
                              moment(v).diff(getValues("end_time")) <= 0
                                ? true
                                : "Start date can't be after End date",
                          },
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ display: "none" }}>
                      <FormDatePicker
                        name="end_time"
                        id="end_time"
                        label="End time"
                        type="datetime-local"
                        defaultValue={null}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>

                    <Grid item xs={2} style={{ display: "none" }}>
                      <FormCheckbox
                        name="is_realtime"
                        id="is_realtime"
                        label="Is Realtime"
                        rules={{}}
                        // defaultValue={true}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={2} style={{ display: "none" }}>
                      <FormCheckbox
                        name="is_active"
                        id="is_active"
                        label="Is Active"
                        rules={{}}
                        defaultValue={true}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                  </>
                </Grid>
              </form>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button sx={{ mr: 2 }} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              disabled={disableSaveBtn}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};

export default Deployments;
