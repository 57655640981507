import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Checkbox, FormControlLabel, IconButton, TextField } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";

interface QuickSearchToolbarProps {
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  clearSearch: () => void;
  onSearchChange: () => void;
  searchValue: string;
  onShowDeletedChange: () => void;
  showDeleted: any;
  showDeletedCheckbox: boolean;
}

export const CustomTableToolbar = ({
  setFilterButtonEl,
  clearSearch,
  onSearchChange,
  searchValue,
  onShowDeletedChange,
  showDeleted,
  showDeletedCheckbox,
}: QuickSearchToolbarProps) => {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarFilterButton ref={setFilterButtonEl} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> */}
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <TextField
          variant="standard"
          value={searchValue}
          onChange={onSearchChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: searchValue ? "visible" : "hidden" }}
                onClick={clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
      {showDeletedCheckbox && (
        <FormControlLabel
          control={
            <Checkbox
              id="showDeletedItems"
              value="showDeletedItems"
              checked={showDeleted}
              onChange={onShowDeletedChange}
            />
          }
          label="Show deleted items"
        />
      )}
    </GridToolbarContainer>
  );
};
