import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const downloadTagsSpreadSheet = (tenantName: string, options: object) => {
  return api.get(
    `${SERVICE_BASE_URLS.SETUP_DATA_SERVICE}/export-to-excel?tenant_name=${tenantName}`,
    options
  );
};

const generateNetReport = (
  tenantName: string,
  data: object,
  options: object
) => {
  return api.post(
    `${SERVICE_BASE_URLS.SETUP_DATA_SERVICE}/get_net_changes_sql?tenant_name=${tenantName}`,
    data,
    options
  );
};

const SetupDataService = {
  downloadTagsSpreadSheet,
  generateNetReport,
};

export default SetupDataService;
