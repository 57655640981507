import api from "./api";

const ML_DEPLOYMENT_ROOT = "/ml-deployment-manager";
const NOTIFICATION_ROOT = "notification-manager";

export const getInProgressDeployment = (options: any) => {
  return api.get(`${ML_DEPLOYMENT_ROOT}/ml-deployments/in-progress-deployment`);
};

export const getDeploymentManagerHealthStatus = () => {
  return api.get(`${ML_DEPLOYMENT_ROOT}/health`);
};

export const createDeployment = (options: any, data: any) => {
  return api.post(`${ML_DEPLOYMENT_ROOT}/ml-deployments`, data, options);
};

export const getDeploymentChannel = (options: any) => {
  return api.get(
    `${NOTIFICATION_ROOT}/notification/channel/deployment`,
    options
  );
};

const DeploymentService = {
  getInProgressDeployment,
  createDeployment,
  getDeploymentChannel,
  getDeploymentManagerHealthStatus,
};

export default DeploymentService;
