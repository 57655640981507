import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import StorageIcon from "@mui/icons-material/Storage";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import ListItem from "@mui/material/ListItem";
import MoreIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { useAuth0 } from "@auth0/auth0-react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ErrorBoundary from "../../components/ErrorBoundary";
// import GlobalSearch from "../../components/GlobalSearch";
import Logo from "../../assets/img/cr_logo_l.svg";
import { useUserContext } from "../../components/UserProvider";
const drawerWidth = 240;
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactNode;
}

export default function ResponsiveDrawer(props: Props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { userRoles } = useUserContext();
  const drawer = (
    <div>
      <Typography variant="h6" noWrap component="div" sx={{ textAlign: "center", padding: "15px" }}>
        <img src={Logo} alt="ControlRooms" width={"150px"} />
      </Typography>
      <Divider />
      <List>
        {(userRoles ?? []).includes("ADMIN") && (
          <>
            <ListItem
              button
              key={"Dashboard"}
              onClick={() => {
                navigate("/");
              }}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
            <ListItem
              button
              key={"Dataplanes"}
              onClick={() => {
                navigate("/dataplanes");
              }}
            >
              <ListItemIcon>
                <DataThresholdingIcon />
              </ListItemIcon>
              <ListItemText primary={"Dataplanes"} />
            </ListItem>
            <ListItem
              button
              key={"Database"}
              onClick={() => {
                navigate("/database");
              }}
            >
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary={"Database"} />
            </ListItem>
            <ListItem
              button
              key={"Simulators"}
              onClick={() => {
                navigate("/simulators");
              }}
            >
              <ListItemIcon>
                <ViewInArIcon />
              </ListItemIcon>
              <ListItemText primary={"Simulators"} />
            </ListItem>
            <ListItem
              button
              key={"Accounts"}
              onClick={() => {
                navigate("/accounts");
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary={"Accounts"} />
            </ListItem>
            <ListItem
              button
              key={"Users"}
              onClick={() => {
                navigate("/users");
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItem>
            <ListItem
              button
              key={"Roles"}
              onClick={() => {
                navigate("/roles");
              }}
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Roles"} />
            </ListItem>
            <ListItem
              button
              key={"Permissions"}
              onClick={() => {
                navigate("/permissions");
              }}
            >
              <ListItemIcon>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText primary={"Permissions"} />
            </ListItem>
            <ListItem
              button
              key={"Clusters"}
              onClick={() => {
                navigate("/clusters");
              }}
            >
              <ListItemIcon>
                <GroupWorkIcon />
              </ListItemIcon>
              <ListItemText primary={"Clusters"} />
            </ListItem>
            <ListItem
              button
              key={"UI"}
              onClick={() => {
                navigate("/ui");
              }}
            >
              <ListItemIcon>
                <ScreenshotMonitorIcon />
              </ListItemIcon>
              <ListItemText primary={"UI"} />
            </ListItem>
            <ListItem
              button
              key={"MLDeployments"}
              onClick={() => {
                navigate("/ml-deployments");
              }}
            >
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText primary={"ML Deployments"} />
            </ListItem>
            <ListItem
              button
              key={"UserGroups"}
              onClick={() => {
                navigate("/user-groups");
              }}
            >
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary={"UserGroups"} />
            </ListItem>
          </>
        )}
        {((userRoles ?? []).includes("SETUP_DATA") || (userRoles ?? []).includes("ADMIN")) && (
          <>
           <ListItem
              button
              key={"Sheet"}
              onClick={() => {
                navigate("/sheet");
              }}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Setup Data"} />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const container = window !== undefined ? () => window().document.body : undefined;
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={"primary-search-account-menu"}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate("/profile");
        }}
      >
        Profile
      </MenuItem>
      <MenuItem onClick={() => logout()}>Logout</MenuItem>
    </Menu>
  );
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={"primary-search-account-menu-mobile"}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#18191D",
          boxShadow: "none",
          backgroundImage: "none",
        }}
      >
        <Toolbar>
          {/* <GlobalSearch /> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          height: "100vh",
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1D2024",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1D2024",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
        {renderMenu}
        {renderMobileMenu}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          pt: 8,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#18191D",
        }}
      >
        {/* <Toolbar /> */}
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
    </Box>
  );
}
