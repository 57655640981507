import { ReactNode, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FamilyDeployments from "./family-deployment";
import Family from "./family";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import DatabaseService from "../../services/database.service";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Deployments from "./deployments";
import { AblyComponent } from "../../components/ably";
import Ably from "ably";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`ml-deployment-panel-${index}`}
      aria-labelledby={`ml-deployment-${index}`}
      {...other}
    >
      {/* {value === index && <Box>{children}</Box>} */}
      <Box sx={{ display: value === index ? "block" : "none" }}>{children}</Box>
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `ml-deployment-${index}`,
    "aria-controls": `ml-deployment-panel-${index}`,
  };
};

const MLDeploymentsLayout = () => {
  const [ablyClient, setAblyClient] = useState<Ably.Realtime | undefined>();
  const [tabValue, setTabValue] = useState(0);
  const [selectedEnv, setSelectedEnv] = useState<any>("");
  const [environments, setEnvironments] = useState<any[]>([]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };


  useEffect(() => {
    (async () => {
      try {
        const databases = await DatabaseService.getDatabases();
        setEnvironments(databases.data.envs);
      } catch (e) {
        console.error("Error", e);
      }
    })();
  }, []);



  return (
    <>
      <Box display="flex" alignItems="center">
        <Autocomplete
          value={selectedEnv}
          onChange={(event: any, newValue: string) => {
            if (newValue) {
              setSelectedEnv(newValue);
            }
          }}
          disablePortal
          id="envs-select-box"
          options={environments}
          sx={{ width: 300, marginTop: "20px" }}
          getOptionLabel={(option) =>
            option.environment_id
              ? `${option.environment_id}-${option.environment_name}`
              : ""
          }
          renderInput={(params) => (
            <TextField
              {...(params as TextFieldProps)}
              label="Select an environment"
            />
          )}
        />
      </Box>
      {tabValue === 2 && (
        <AblyComponent ablyClient={ablyClient} setAblyClient={setAblyClient} />
      )}

      <Box component="main" sx={{ maxWidth: "1550px", marginTop: "10px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Family Deployments" {...a11yProps(0)} />
            <Tab label="Family" {...a11yProps(1)} />
            <Tab label="Deployments" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <FamilyDeployments selectedEnv={selectedEnv} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Family selectedEnv={selectedEnv} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Deployments
            inProgressDeployment={null}
            ablyClient={ablyClient}
            selectedEnv={selectedEnv}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default MLDeploymentsLayout;
