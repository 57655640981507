import { useEffect, useState } from "react";
import List from "@mui/material/List";
import { AblyMessageCallback, ChannelParameters, useChannel } from "ably/react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Ably from "ably";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { ListItemIcon, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { convertUTCToLocalTime } from "../utils/utils";


interface MessageData {
  time: string;
  tenant_id: string;
  message_id: string;
  type: string;
  source: string;
  payload: any;
}

interface ChannelMessageProps { 
  selectedEnv: any;
  channelName: string;
}

const ChannelMessage = ({ selectedEnv, channelName }: ChannelMessageProps) => {
  const channelEvent = "event";
  const localDateFormat = "YYYY-MM-DD HH:mm:ss";
  const [messages, setMessages] = useState<any>({});

  const handleMessage = (message: Ably.Message) => {
    const jsonObject: MessageData = JSON.parse(message.data);

    setMessages((prevState: any) => {
      const envId = selectedEnv.environment_id;
      if (prevState[envId]) {
        return {
          ...prevState,
          [envId]: [...prevState[envId], jsonObject],
        };
      } else {
        return { ...prevState, [envId]: [jsonObject] };
      }
    });
  };

  const { channel, channelError, connectionError } = useChannel(
    channelName as ChannelParameters,
    channelEvent as string,
    handleMessage as AblyMessageCallback
  );

  useEffect(() => {
    if (connectionError) {
      console.error("Connection Error:", connectionError.message);
    }
    if (channelError) {
      console.error("Channel Error:", channelError.message);
    }

    // return () => {
    //   channel?.unsubscribe();
    // };
  }, [connectionError, channelError, channel]);

  if (connectionError) {
    return <div>Connection Error: {connectionError.message}</div>;
  }

  if (channelError) {
    return <div>Channel Error: {channelError.message}</div>;
  }

  return (
    <>
      <Box
        component={Paper}
        sx={{
          maxWidth: "1550px",
          padding: "10px",
          // height: "700px",
          // overflow: "hidden",
          // overflowY: "scroll",
        }}
      >
        <h4
          style={{ color: "#7AC231" }}
        >{`Env: ${selectedEnv.environment_id} - ${selectedEnv.environment_name}`}</h4>
        {!messages?.[selectedEnv.environment_id] && (
          <p>Waiting for the messages...</p>
        )}
        <List>
          {messages?.[selectedEnv.environment_id]?.map(
            (message: any, index: number) => (
              <ListItem key={index}>
                <ListItemIcon>
                  {message.type === "SUCCESS" ? (
                    <CheckIcon style={{ color: "#7AC231" }} />
                  ) : (
                    <ClearIcon style={{ color: "#FF0000" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography component="span">
                      <Typography component="span" color="yellow">
                        {`${convertUTCToLocalTime(
                          message.time,
                          localDateFormat
                        )} - `}
                      </Typography>

                      {message.source}
                    </Typography>
                  }
                  secondary={
                    message?.type === "SUCCESS"
                      ? message?.payload?.message
                      : message?.payload?.error
                  }
                />
              </ListItem>
            )
          )}
        </List>
      </Box>
    </>
  );
};

export default ChannelMessage;
