import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getFamilies = (options: object) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/families`, options);
};

const createFamily = (data: object, options: object) => {
  return api.post(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family`,
    data,
    options
  );
};

const updateFamily = (family_id: number, data: object, options: object) => {
  return api.put(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family/${family_id}`,
    data,
    options
  );
};

const FamilyService = {
  getFamilies,
  createFamily,
  updateFamily,
};

export default FamilyService;
