import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { StoreProvider } from "easy-peasy";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import store from "./store";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Auth0ProviderWithHistory from "./components/auth";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { NotificationProvider } from "./components/NotificationProvider";
import { LoaderProvider } from "./components/LoaderProvider";
import "./index.scss";
import { UserProvider } from "./components/UserProvider";

Sentry.init({
  dsn: "https://805b15b9014847a59d5aa7e17751e1be@o1305961.ingest.sentry.io/6553243",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#99CDCB",
    },
  },
});

const StoreProviderOverride = StoreProvider as any;
const root = createRoot(document.getElementById("root") as HTMLDivElement);

root.render(
  <Auth0ProviderWithHistory>
    <StoreProviderOverride store={store}>
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <LoaderProvider>
              <NotificationProvider>
                <UserProvider>
                  <App />
                </UserProvider>
              </NotificationProvider>
            </LoaderProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StoreProviderOverride>
  </Auth0ProviderWithHistory>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
