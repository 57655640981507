import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from 'react-hook-form';
import { FormInput } from '../../components/FormInput';
import { FormAutocomplete } from '../../components/FormAutoComplete';

import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import DatabaseService from '../../services/database.service';
import UserGroupService from '../../services/user-group.service';
import { constructFamilyUserGroupDetail, constructUserGroupDetail } from '../../utils/utils';
import useNotifications from '../../components/NotificationProvider';
import useLoader from '../../components/LoaderProvider';
import FamilyService from '../../services/famiy.service';

interface FormState {
  visible: boolean;
  heading: string;
}

const UserGroups = () => {

  const { t } = useTranslation("common")
  const [openUserGroupForm, setOpenUserGroupForm] = useState<FormState>({ visible: false, heading: '' })
  const [tableRows, setTableRows] = useState<any[]>([])
  const [environments, setEnvironments] = useState<any[]>([])
  const [envInputValue, setEnvInputValue] = useState('');
  const [selectedEnv, setSelectedEnv] = useState<any>('')
  const { setNotification } = useNotifications();
  const { setLoading } = useLoader();
  const [families, setFamilies] = useState<any[]>()
  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm();
  
  const handleOpenUserGroupFormClick = (visible: boolean, heading: string) => {
    if (heading === 'Create') {
      reset({group_desc: '', families: [], main_family: ''})
      setMainFamilyOptions([])
    }
    setOpenUserGroupForm({ visible, heading });
  }

  const handleEditClick = (user_group_id: any) => {
    const editRow = tableRows.find((row) => row.user_group_id === user_group_id)
    reset({ ...editRow })
    setMainFamilyOptions(editRow.families)
  }

  const onSubmit = async (values: any) => {
    const user_group_id = values?.user_group_id
    const options = {
      headers: { "env-id": selectedEnv.environment_id }
    }

    try {
      const requestBody = constructUserGroupDetail(values)
      let response;
      if (user_group_id) {
        response = await UserGroupService.updateUserGroup(user_group_id, selectedEnv.tenant_id, requestBody)
      } else {
        requestBody.tenant_id = selectedEnv.tenant_id
        response = await UserGroupService.createUserGroup(requestBody)
      }

      if (response?.status === 200) {
        const user_group_id_from_resp = response?.data?.user_group_id;
        const data = constructFamilyUserGroupDetail(values);
    
        if (user_group_id) {
            // User group families update while updating user group
            await UserGroupService.updateFamilyUserGroups(user_group_id_from_resp, options, data);
        } else if (data?.family_ids?.length) { 
            // User group families update while creating user group, Ignore this if there are no families mapped
            await UserGroupService.updateFamilyUserGroups(user_group_id_from_resp, options, data);
        }
      }
    

      setNotification({
        message: `User group ${user_group_id ? "Updated" : "Created"} successfully`,
        open: true,
      });
      setOpenUserGroupForm({ visible: false, heading: '' })
      getUserGroupsByTenantID()
      reset({})
    } catch(e: any) {
      setNotification({
        message: e?.detail ?? `Error occurred while ${user_group_id ? "updating" : "creating"} the user group`,
        open: true,
        type: "error",
      });
      console.error("Error", e)
    }
    
  };

  const getUserGroupsByTenantID = useCallback(async () => {
    try {
      const options = {
        headers: { "env-id": selectedEnv.environment_id }
      }
      
      setLoading(true)
      if(selectedEnv) {
        const response = await UserGroupService.getFamilyUserGroupsByTenantID(selectedEnv?.tenant_id, options)
        setTableRows(response.data.user_groups)
        const familiesResp = await FamilyService.getFamilies(options)
        const familyListResponse = familiesResp.data.data
        setFamilies(familyListResponse)
      }
    } catch (e) {
      setTableRows([])
      console.log("Error",e)
    } finally{
      setLoading(false)
    }
  },[selectedEnv, setLoading])

  useEffect(() => {
    (async () => {
      try {
        const databases = await DatabaseService.getDatabases();
        setEnvironments(databases.data.envs)

      } catch (e) {
        setEnvironments([])
        console.error("Error", e);
      }
    }

    )();

  }, []);


  useEffect(() => {
    if (selectedEnv) {
      getUserGroupsByTenantID()
    }
  }, [getUserGroupsByTenantID, selectedEnv]);

  const [mainFamilyOptions, setMainFamilyOptions] = useState<any[]>([])
  const handleOnClose = () => {
    setOpenUserGroupForm({ ...openUserGroupForm, visible: false });
  };

  return (
    <>
      <Autocomplete
        value={selectedEnv}
        onChange={(event: any, newValue: string) => {
          if(newValue) {
            setSelectedEnv(newValue);
          }
        }}
        inputValue={envInputValue}
        onInputChange={(event, newInputValue) => {
          setEnvInputValue(newInputValue);
        }}
        disablePortal
        id="envs-select-box"
        options={environments}
        sx={{ width: 300, marginTop: "20px" }}
        getOptionLabel={(option) => option.environment_id ? `${option.environment_id}-${option.environment_name}` : ''}
        renderInput={(params) => <TextField {...params as TextFieldProps} label="Select an environment" />}
      />
      <Card sx={{ backgroundColor: "#2A2B31", maxWidth: "700px", marginTop: "10px" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <CardHeader
            action={[
              <Button
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  handleOpenUserGroupFormClick(true, 'Create')
                }}
                variant="contained"
                disabled={!selectedEnv}
              >
                Create
              </Button>
            ]}
            title={t("common:user_groups.title")}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} arial-label="user-group table" >
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Group Description</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Families</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
                {tableRows.length > 0 ? (
                  tableRows.map((row: any) => (
                    <TableRow
                      key={row.user_group_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 }
                      }}
                    >
                      <TableCell>
                        <Typography
                          variant="body2"
                          display="block"
                          color="#7AC231"
                        >
                          {row.group_desc}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="body2"
                          display="block"
                          color="#7AC231"
                        >
                          {row.families && row.families.map((item: any) => item.name).join(', ')}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <EditIcon
                          sx={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => {
                            handleOpenUserGroupFormClick(true, 'Edit')
                            handleEditClick(row.user_group_id)
                          }
                          }

                        />
                        {/* <DeleteIcon
                            sx={{ cursor: "pointer", marginRight: "10px" }}
                            
                          /> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      No rows Found
                    </TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openUserGroupForm.visible}
        onClose={handleOnClose}

      >
        <DialogTitle>
          {`${openUserGroupForm.heading} User Group`}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
              // pr={2}
              >
                <Grid item xs={6}>
                  <FormInput
                    name="group_desc"
                    type="string"
                    id="group_desc"
                    label="Group Desc"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name="families"
                    id="families"
                    label="Families"
                    options={families}
                    labelKey="name"
                    valueKey="family_id"
                    multiple
                    handleOnChange={(values: any) => {
                      const currentMainFamilyVal = getValues("main_family")
                      if (currentMainFamilyVal && !values.some((option: any) => option.family_id === currentMainFamilyVal.family_id)) {
                        setValue("main_family", '');
                      }
                      setMainFamilyOptions(values)
                    }}
                    // rules={{
                    //   required: "This field is required",
                    // }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                  
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name="main_family"
                    id="main_family"
                    label="Main Family"
                    options={mainFamilyOptions}
                    labelKey="name"
                    valueKey="family_id"
                    rules={{
                      validate: {
                        checkIfFamilySelected: (v: any) => {
                          if (mainFamilyOptions.length > 0 && !v) {
                            return "Main family must be selected if family selected";
                          }
                          return true;
                        }
                      }
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenUserGroupForm({ ...openUserGroupForm, visible: false })}>
            Close
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </DialogActions>

      </Dialog>
    </>
  );
};

export default UserGroups;
