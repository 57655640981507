import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getGlobalConfigDefaults = () => {
    return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/config/defaults`);
};

const GlobalConfigService= {
    getGlobalConfigDefaults
};

export default GlobalConfigService;