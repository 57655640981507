import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getDatabases = () => {
  return api.get(`${SERVICE_BASE_URLS.DB_MANAGER}/db-environments/active`);
};

const getDBDetail = (id: string | undefined) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/${id}`);
};

const createBackup = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/backup`, data);
};

const restoreBackup = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/backup/restore`, data);
};

const removeBackup = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/backup/remove`, data);
};

const resampleDatabase = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/databases/resample`, data);
};

const bulkDBUpgrade = (data: any) => {
  return api.put(`${SERVICE_BASE_URLS.DB_MANAGER}/db-environments/bulk_upgrade`, data);
};

const bulkRuleEngineUpgrade = (data: any) => {
  return api.put(`${SERVICE_BASE_URLS.DB_MANAGER}/db-environments/rule-engine/bulk_upgrade`, data);
};
const deleteDBEnvironment = (env_id: number) => {
  return api.delete(`${SERVICE_BASE_URLS.DB_MANAGER}/db-environments/${env_id}`);
};

const DatabaseService = {
  getDatabases,
  getDBDetail,
  createBackup,
  restoreBackup,
  removeBackup,
  resampleDatabase,
  bulkDBUpgrade,
  deleteDBEnvironment,
  bulkRuleEngineUpgrade
};
export default DatabaseService;
