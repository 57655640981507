import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import InfiniteScroll from "react-infinite-scroll-component";

import DetailCard from "../../../components/DetailCard";
import useNotifications from "../../../components/NotificationProvider";
import { FormInput } from "../../../components/FormInput";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { FormAsyncAutocomplete } from "../../../components/FormAsyncAutoComplete";
import ColumnHeader from "../../../components/ColumnHeader";
import OrganizationService from "../../../services/organization.service";
import { useStoreState, useStoreActions } from "../../../store/hooks";
import { constructOrganization } from "../../../utils/utils";
import { IAccountsColumn } from "../../../types/app/accounts.type";
import { IOrganization } from "../../../types/api/organization.type";

import "./account.scss";

interface IOrganizationsColumn extends IAccountsColumn {}

const OrganizationsColumn = ({ setSelectedColumn }: IOrganizationsColumn) => {
  const { selectedAccount } = useStoreState((store) => store.account);
  const { organizations, selectedOrganization, totalOrganization, loading } = useStoreState(
    (store) => store.organization
  );
  const {
    setSelectedOrganization,
    clearOrganizationState,
    getOrganizationsByAccountId,
    createOrganization,
    editOrganization,
    deleteOrganization,
  } = useStoreActions((store) => store.organization);
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [page, setPage] = useState(1);
  const [selectedOrganizationDetail, setSelectedOrganizationDetail] = useState<any>({});
  const [openOrganizationDialog, setOpenOrganizationDialog] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const { setNotification } = useNotifications();
  const [searchTerm, setSearchTerm] = useState("");
  const searchRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      clearOrganizationState();
      resetSearchInput();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount.account_id]);

  useEffect(() => {
    loadData(1);
    setSelectedColumn({ id: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount.account_id, searchTerm]);

  const loadData = async (initialPage = 0) => {
    try {
      await getOrganizationsByAccountId({
        accountId: selectedAccount.account_uuid as string,
        data: {
          count_per_page: 10,
          page_no: initialPage || page,
          filter: {
            org_name: searchTerm,
          },
          show_deleted: false,
        },
      });
      setPage((initialPage || page) + 1);
    } catch (error) {
    }
  };

  const onSubmit = async (values: any) => {
    try {
      let org_uuid = values.org_uuid;
      values.account_id = selectedAccount.account_id;
      values.parent_org = values?.parent_org?.org_uuid;
      if (org_uuid) {
        const constructedData = constructOrganization(values);
        await editOrganization({ data: constructedData, org_uuid });
      } else {
        await createOrganization(values);
      }
      setOpenOrganizationDialog(false);
      setNotification({
        message: `Organization ${org_uuid ? "updated" : "created"}successfully`,
        open: true,
      });
      loadData(1);
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteOrganization(selectedOrganization.org_uuid as string);
      setOpenDeleteConfirmation(false);
      setNotification({ message: "Organization deleted successfully", open: true });
      setSelectedColumn({ id: 1, name: "account" });
      loadData(1);
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleOnCardClick = async (data: IOrganization) => {
    setSelectedColumn({ id: 2, name: "organization" });
    setSelectedOrganization(data);
    const result = await OrganizationService.getOrganizationById(data.org_uuid as string);
    const orgDetail = result.data;
    setSelectedOrganizationDetail({
      locations: {
        count: orgDetail.location_count,
        icon: <LocationOnIcon />,
      },
      tenants: {
        count: orgDetail.tenant_count,
        icon: <GroupsIcon />,
      },
      users: {
        count: orgDetail.user_count,
        icon: <PersonIcon />,
      },
    });
    setSelectedOrganization(orgDetail);
  };

  const handleEditClick = () => {
    reset({ ...selectedOrganization });
    setOpenOrganizationDialog(true);
  };

  const handleDeleteClick = () => {
    setOpenDeleteConfirmation(true);
  };

  const resetSearchInput = () => {
    if (searchRef.current !== null) {
      searchRef?.current.resetSearch();
    }
  };

  return (
    <>
      <Box sx={{ borderRight: 1, borderColor: "#5B5E67", pl: 1 }}>
        <ColumnHeader
          title={t("common:organizations.title")}
          handleOnSearch={setSearchTerm}
          handleOnAdd={() => {
            setOpenOrganizationDialog(true);
          }}
          ref={searchRef}
        />
        <Box
          id="organizationScroll"
          sx={{
            height: "calc(100vh - 150px)",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#757575",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
              borderRadius: 2,
            },
            overflow: "auto",
            pr: 1,
          }}
        >
          <InfiniteScroll
            dataLength={organizations.length}
            next={loadData}
            hasMore={organizations.length < totalOrganization}
            loader={<h4>Loading...</h4>}
            scrollableTarget="organizationScroll"
          >
            {loading && (
              <Typography align="center" p={2}>
                Loading...
              </Typography>
            )}
            {organizations.map((organization: IOrganization, index: number) => (
              <DetailCard
                key={organization.org_id}
                data={organization}
                details={selectedOrganizationDetail}
                title={organization.org_name}
                subheader={`Acc: #${organization.account_id}`}
                id={organization.org_id}
                showDetailView={
                  organization.org_id === selectedOrganization.org_id
                }
                handleOnCardClick={handleOnCardClick}
                handleEdit={handleEditClick}
                handleDelete={handleDeleteClick}
              />
            ))}
            {!organizations.length && !loading && (
              <Typography align="center" p={2}>
                No records found
              </Typography>
            )}
          </InfiniteScroll>
        </Box>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openOrganizationDialog}
        onClose={() => setOpenOrganizationDialog(false)}
      >
        <DialogTitle>{`${
          getValues("org_id") ? "Edit" : "Create"
        } organization`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <Typography>{`${selectedAccount.account_name} >`}</Typography>
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={10}>
                  <FormInput
                    name="org_name"
                    type="text"
                    id="org_name"
                    label="Name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={10}>
                  <FormAsyncAutocomplete
                    name="parent_org"
                    id="parent_org"
                    label="Parent Organization"
                    getOptions={(searchTerm: string) =>
                      OrganizationService.getOrganizations({
                        count_per_page: 10,
                        page_no: 1,
                        filter: { org_name: searchTerm },
                        show_deleted: false,
                      })
                    }
                    labelKey="org_name"
                    valueKey="org_id"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            sx={{ mr: 2 }}
            onClick={() => {
              setOpenOrganizationDialog(false);
              reset({});
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="If you are sure you want to delete the selected organization?"
        confirmationText={selectedOrganization.org_name}
      />
    </>
  );
};

export default React.memo(OrganizationsColumn);
