import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupsIcon from "@mui/icons-material/Groups";
import InfiniteScroll from "react-infinite-scroll-component";

import DetailCard from "../../../components/DetailCard";
import { FormInput } from "../../../components/FormInput";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import useNotifications from "../../../components/NotificationProvider";
import ColumnHeader from "../../../components/ColumnHeader";
import AccountService from "../../../services/account.service";
import { useStoreState, useStoreActions } from "../../../store/hooks";
import { constructAccount } from "../../../utils/utils";
import { IAccountsColumn } from "../../../types/app/accounts.type";
import { IAccount } from "../../../types/api/accounts.type";

import "./account.scss";

const AccountsColumn = ({ setSelectedColumn }: IAccountsColumn) => {
  const { accounts, selectedAccount, totalAccount, loading } = useStoreState((store) => store.account);
  const { setSelectedAccount, clearAccountState, getAccounts, createAccount, editAccount, deleteAccount } =
    useStoreActions((store) => store.account);
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [page, setPage] = useState(1);
  const [openAccountDialog, setAccountDialogOpen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedAccountDetail, setSelectedAccountDetail] = useState<any>({});
  const { setNotification } = useNotifications();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    return () => {
      clearAccountState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData(1);
    setSelectedColumn({ id: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const loadData = async (initialPage = 0) => {
    try {
      await getAccounts({
        count_per_page: 10,
        page_no: initialPage || page,
        filter: {
          account_name: searchTerm,
        },
        show_deleted: false,
      });
      setPage((initialPage || page) + 1);
    } catch (error) {
      console.log(error)
    }
  };

  const onSubmit = async (values: any) => {
    try {
      const account_uuid = values.account_uuid;
      if (account_uuid) {
        const constructedData = constructAccount(values);
        await editAccount({ data: constructedData, account_uuid });
      } else {
        await createAccount(values);
      }
      setAccountDialogOpen(false);
      setNotification({
        message: `Account ${account_uuid ? "updated" : "created"}successfully`,
        open: true,
      });
      loadData(1);
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteAccount(selectedAccount.account_uuid as string);
      setOpenDeleteConfirmation(false);
      setNotification({ message: "Account deleted successfully", open: true });
      loadData(1);
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleOnCardClick = async (data: IAccount) => {
    setSelectedColumn({ id: 1, name: "account" });
    setSelectedAccount(data);
    const result = await AccountService.getAccountById(data.account_uuid as string);
    const accountDetail = result.data;
    setSelectedAccountDetail({
      organizations: {
        count: accountDetail.organization_count,
        icon: <CorporateFareIcon />,
      },
      locations: {
        count: accountDetail.location_count,
        icon: <LocationOnIcon />,
      },
      tenants: {
        count: accountDetail.tenant_count,
        icon: <GroupsIcon />,
      },
    });
    setSelectedAccount(accountDetail);
  };

  const handleEditClick = () => {
    reset({ ...selectedAccount });
    setAccountDialogOpen(true);
  };

  const handleDeleteClick = () => {
    setOpenDeleteConfirmation(true);
  };

  return (
    <>
      <Box sx={{ borderRight: 1, borderColor: "#5B5E67", pl: 1 }}>
        <ColumnHeader
          title={t("common:accounts.title")}
          handleOnSearch={setSearchTerm}
          handleOnAdd={() => {
            setAccountDialogOpen(true);
          }}
        />
        <Box
          id="accountScroll"
          sx={{
            height: "calc(100vh - 150px)",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#757575",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
              borderRadius: 2,
            },
            overflow: "auto",
            pr: 1,
          }}
        >
          <InfiniteScroll
            dataLength={accounts.length}
            next={loadData}
            hasMore={accounts.length < totalAccount}
            loader={<h4>Loading...</h4>}
            scrollableTarget="accountScroll"
          >
            {loading && (
              <Typography align="center" p={2}>
                Loading...
              </Typography>
            )}
            {accounts.map((account: IAccount, index: number) => (
              <DetailCard
                key={account.account_id}
                data={account}
                details={selectedAccountDetail}
                title={account.account_name}
                subheader={`#${account.account_id} - ${account.subdomain}`}
                id={account.account_id}
                showDetailView={
                  account.account_id === selectedAccount.account_id
                }
                handleOnCardClick={handleOnCardClick}
                handleEdit={handleEditClick}
                handleDelete={handleDeleteClick}
              />
            ))}
            {!accounts.length && !loading && (
              <Typography align="center" p={2}>
                No records found
              </Typography>
            )}
          </InfiniteScroll>
        </Box>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openAccountDialog}
        onClose={() => setAccountDialogOpen(false)}
      >
        <DialogTitle>{`${
          getValues("account_id") ? "Edit" : "Create"
        } account`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={6}>
                  <FormInput
                    name="account_name"
                    type="string"
                    id="account_name"
                    label="Account name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    mt={2}
                    color="#888"
                    sx={{ fontWeight: "bold" }}
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="address_line_1"
                    type="string"
                    id="address_line_1"
                    label="Address Line 1"
                    extraFieldData={{
                      multiline: true,
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="address_line_2"
                    type="string"
                    id="address_line_2"
                    label="Address Line 2"
                    extraFieldData={{
                      multiline: true,
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="city"
                    type="string"
                    id="city"
                    label="City"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="state"
                    type="string"
                    id="state"
                    label="State"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="country"
                    type="string"
                    id="country"
                    label="Country"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="postal"
                    type="number"
                    id="postal"
                    label="Postal"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    mt={2}
                    color="#888"
                    sx={{ fontWeight: "bold" }}
                  >
                    Domain config
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="subdomain"
                    type="string"
                    id="subdomain"
                    label="Sub domain"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            sx={{ mr: 2 }}
            onClick={() => {
              setAccountDialogOpen(false);
              reset({});
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="If you are sure you want to delete the selected account?"
        confirmationText={selectedAccount.account_name}
      />
    </>
  );
};

export default React.memo(AccountsColumn);
