import moment from "moment";
import { IAccount } from "../types/api/accounts.type";
import { ILocation } from "../types/api/location.type";
import { IOrganization } from "../types/api/organization.type";
import { IPermission } from "../types/api/permission.type";
import { IRole } from "../types/api/role.type";
import { ITenant, ITenantRole } from "../types/api/tenant.type";

export const constructAccount = (account: IAccount) => {
  return {
    account_id: account.account_id,
    account_uuid: account.account_uuid,
    account_name: account.account_name,
    address_line_1: account.address_line_1 || "",
    address_line_2: account.address_line_2 || "",
    city: account.city || "",
    country: account.country || "",
    postal: account.postal || "",
    state: account.state || "",
    subdomain: account.subdomain || "",
  };
};

export const constructOrganization = (organization: IOrganization) => {
  return {
    account_id: organization.account_id,
    org_name: organization.org_name,
    parent_org: organization.parent_org,
  };
};

export const constructLocation = (location: ILocation) => {
  return {
    loc_name: location.loc_name,
    geo: location.geo,
    org_id: location.org_id,
    timezone: location.timezone,
  };
};

export const constructTenant = (tenant: ITenant) => {
  return {
    loc_id: tenant.loc_id,
    tenant_name: tenant.tenant_name,
    preferences: JSON.parse(tenant.preferences || null),
  };
};

export const constructRole = (roles: any[]) => {
  return roles.map((role: any) => {
    if (typeof role === "object") {
      role = role.role_id;
    }
    return role;
  });
};

export const constructTenantRole = (tenant_roles: any[]) => {
  return tenant_roles.map((tenant_role: any) => {
    if (!tenant_role.tenant_id.tenant_id || !tenant_role.tenant_id) {
      return {};
    }
    return {
      loc_id: tenant_role.loc_id.loc_id || tenant_role.loc_id,
      tenant_id: tenant_role.tenant_id.tenant_id || tenant_role.tenant_id,
      roles: constructRole(tenant_role.roles),
    };
  });
};

export const constructUserGroup = (tenantInfos: any[]) => {
  return tenantInfos.flatMap((tenantInfo: any) => {
    return tenantInfo.user_groups.map((data: any) => {
      return data.user_group_id;
    });
  });
};

export const constructDefaultUserGroups = (tenant_roles: ITenantRole[]): string[] => {
  return tenant_roles
    .map((data) => data.default_user_group?.user_group_id)
    .filter((id): id is string => id !== undefined);
};

export const constructUser = (user: any) => {
  return {
    org_id: user.org_id.org_id || user.org_id,
    phone_number: user.phone_number || "",
    preferences: JSON.parse(user.preferences || {}),
    primary_email: user.primary_email || "",
    tenant_roles: constructTenantRole(user.tenant_roles),
    secondary_email: user.secondary_email || "",
    sms_number: user.sms_number || "",
    user_groups: !user.user_groups ? [] : constructUserGroup(user.tenant_roles),
    default_user_groups: constructDefaultUserGroups(user.tenant_roles),
    admin_roles: user.admin_roles || [],
  };
};

export const constructOperation = (operations: any[]) => {
  return operations.map((operation: any) => {
    if (typeof operations === "object") {
      operation = operation.operation_name;
    }
    return operation;
  });
};

export const constructPermissionDetail = (permission: IPermission) => {
  return {
    permission_desc: permission.permission_desc,
    operations: { ...permission.operations, operations: constructOperation(permission.operations?.operations) },
  };
};

export const constructRolePermissionDetail = (role: IRole) => {
  return {
    permissions: role.permissions.length && role.permissions?.map(({ permission_id }: any) => permission_id),
  };
};


export const constructFamilyDeploymentDetail = (
  data: any,
  editMode: boolean
) => {
  const result = {
    is_realtime: JSON.parse(data.is_realtime),
    start_time: moment(data.start_time).utc().format(),
    end_time: data.end_time ? moment(data.end_time).utc().format() : null,
    is_active: JSON.parse(data.is_active),
    model_uri: data.model_uri,
    endpoint_id: data.endpoint_id,
  };

  if (!editMode) {
    return {
      ...result,
      family_id: data.family_id.family_id,
      process_id: data.process_id.process_id,
      ensemble_id: Number(data.ensemble_id),
    };
  }

  return result;
};

export const constructUserGroupDetail = (data: any): any => {
  return {
    group_desc: data.group_desc,
  };
};


export const constructFamilyUserGroupDetail = (values: any): any => {
  const familyIds = Array.isArray(values.families) ? values.families.map((data: any) => data.family_id) : [];
  const mainFamilyId = values.main_family?.family_id ? values.main_family.family_id : null;
  return {
    family_ids: familyIds,
    main_family: mainFamilyId,
  };
};


export const convertUTCToLocalTime = (utcDateStr: string, format?: string | undefined): string => {
  return moment.utc(utcDateStr).local().format(format ? format : "YYYY-MM-DD HH:mm");
};

export const constructModelList = (modelList: any[]) => {
  const newModelList =  modelList.map((modelItem) => {
    console.log(modelItem);
    return {
      family_id: modelItem.family.family_id,
      process_ensembles: modelItem.process_ensembles.map(
        (processEnsemble: any) => {
          return {
            ensemble_id: processEnsemble.ensemble_id,
            ensemble_name: processEnsemble.ensemble_name,
            process_id: processEnsemble.process_id,
            process_name: processEnsemble.process_name,
          };
        }
      ),
    };
  });

  return newModelList;
};

export const constructDeployementDetail = (data: any) => {
  const deploymentData = {
    model_list: constructModelList(data.model_list),
    endpoint_id: data.endpoint_id,
    compute_name: data.compute_name,
    instance_type: data.instance_type,
    auto_scale_max_replicas: JSON.parse(data.auto_scale_max_replicas),
    azure_ml_block_name: data.azure_ml_block_name,
    prefect_db_block: data.prefect_db_block,
    cpu_cores: JSON.parse(data.cpu_cores),
    memory_gb: JSON.parse(data.memory_gb),
    num_replicas: JSON.parse(data.num_replicas),
    schema_system: null,
    enable_app_insights: JSON.parse(data.enable_app_insights),
  };

  return deploymentData;
};
