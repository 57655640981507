import { ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Card, CardHeader, CardContent, Box, Tabs, Tab, Grid, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CodeTextEditor from "../../components/CodeEditor";
import DataplaneService from "../../services/dataplane.service";
import { useLocation } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { FormInput } from "../../components/FormInput";
import { ITableRowsState } from "./index";
import { batchMock } from "../../global/constants";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { FormAutocomplete } from "../../components/FormAutoComplete";
import { FormAsyncAutocomplete } from "../../components/FormAsyncAutoComplete";
import OrganizationService from "../../services/organization.service";
import { getTimeZones } from "../../global/timezones";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dataplane-panel-${index}`}
      aria-labelledby={`dataplane-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `dataplane-${index}`,
    "aria-controls": `dataplane-panel-${index}`,
  };
};

const Dataplanes = () => {
  const { t } = useTranslation("common");
  const [tabValue, setTabValue] = useState(0);
  const [openConfigDialog, setConfigDialogOpen] = useState(false);
  const { state } = useLocation();
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openSuspendConfirmation, setOpenSuspendConfirmation] = useState(false);
  const [openResumeConfirmation, setOpenResumeConfirmation] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [isPromote, setIsPromote] = useState(false);
  const [rowsState, setRowsState] = useState<ITableRowsState>({
    page: 0,
    pageSize: 5,
  });
  const [tableRows, setTableRows] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);

  const columns: GridColDef[] = [
    { field: "userid", headerName: "User", flex: 1 },
    { field: "location_name", headerName: "Location name", flex: 1 },
    { field: "location_tz", headerName: "Time zone", flex: 1 },
    { field: "tenantid", headerName: "Tenant", flex: 1 },
    { field: "storage", headerName: "Storage", flex: 1 },
    { field: "cpus", headerName: "Cpu", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <EditIcon
            sx={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => {
              console.log("edit---", params);
              setConfigDialogOpen(true);
              reset({ ...params.row });
            }}
          />
          <DeleteIcon
            sx={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => {
              console.log("delete---", params);
              setSelectedRow([{ ...params.row }]);
              setOpenDeleteConfirmation(true);
            }}
          />
        </>
      ),
    },
  ];

  const timezones = useMemo(getTimeZones, []);

  useEffect(() => {
    console.log("state---", state);
    getDataplaneDetails();
  }, [state]);

  const getDataplaneDetails = async () => {
    try {
      const dataplanes = await DataplaneService.getDataplanes();
      console.log("databases---", dataplanes);
      setTableRows(batchMock);
    } catch (e) {
      console.error("----", e);
      setTableRows(batchMock);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSubmit = async (values: any) => {
    try {
      let result: any;
      if (!values.id) {
        result = await DataplaneService.createBatchEnv(values);
      } else {
        values.userId = 1;
        result = await DataplaneService.promoteBatchEnv(values);
      }
      console.log("result---", result);
      setConfigDialogOpen(false);
    } catch (e) {
      console.error("----", e);
    }
  };

  const onAssignSubmit = async (values: any) => {
    try {
      await DataplaneService.assignUserToEnv(values);
      setOpenAssignDialog(false);
    } catch (e) {
      console.error("----", e);
    }
  };

  const handleDelete = async () => {
    try {
      const data = { selectedRow };
      const result = await DataplaneService.deleteBatchEnv(data);
      console.log("result---", result);
    } catch (e) {
      console.error("----", e);
    }
  };

  const handleSuspendAndResume = async (action: string) => {
    try {
      const data = { selectedRow };
      const result = await DataplaneService.suspendOrResumeBatchEnv({
        data,
        action,
      });
      console.log("result---", result);
    } catch (e) {
      console.error("----", e);
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardHeader
          action={
            <>
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => {
                  setOpenSuspendConfirmation(true);
                }}
                disabled={!selectedRow.length}
              >
                Suspend
              </Button>
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => {
                  setOpenResumeConfirmation(true);
                }}
                disabled={!selectedRow.length}
              >
                Resume
              </Button>
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => {
                  reset({ ...selectedRow[0] });
                  setOpenAssignDialog(true);
                }}
                disabled={!selectedRow.length}
              >
                Assign
              </Button>
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => {
                  reset({ ...selectedRow[0] });
                  setIsPromote(true);
                  setConfigDialogOpen(true);
                }}
                disabled={!selectedRow.length}
              >
                Promote
              </Button>
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => {
                  setConfigDialogOpen(true);
                }}
              >
                Create
              </Button>
            </>
          }
          title={t("common:dataplanes.Environments")}
        />
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <DataGrid
            autoHeight
            rows={tableRows}
            columns={columns}
            rowsPerPageOptions={[5]}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = tableRows.filter((row: any) => {
                return selectedIDs.has(row.id);
              });
              console.log(selectedRowData);
              setSelectedRow(selectedRowData);
            }}
            // rowCount={20}
            disableSelectionOnClick
            // paginationMode="server"
            // sortingMode="server"
            // onSortModelChange={handleSortModelChange}
            // filterMode="server"
            // onFilterModelChange={onFilterChange}
            {...rowsState}
            onPageChange={(page) => setRowsState((prev: any) => ({ ...prev, page }))}
          />
        </CardContent>
      </Card>
      <Dialog fullWidth={true} maxWidth="md" open={openConfigDialog} onClose={() => setConfigDialogOpen(false)}>
        <DialogTitle>{getValues("id") ? "Edit" : "Create"} Batch Environment</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Configuration" {...a11yProps(0)} />
                <Tab label="Yaml" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <form>
                <Grid container spacing={2} mt={1} pr={2} pb={2} sx={{ overflow: "auto" }}>
                  <Grid item xs={6}>
                    <FormInput
                      name="location_name"
                      type="string"
                      id="locationName"
                      label="Location name"
                      rules={{
                        required: "This field is required",
                      }}
                      extraFieldData={{
                        multiline: true,
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormAutocomplete
                      name="location_tz"
                      type="string"
                      id="locationTz"
                      label="Location timezone"
                      options={timezones}
                      labelKey="label"
                      valueKey="label"
                      rules={{
                        required: "This field is required",
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormInput
                      name="storage"
                      type="number"
                      id="storage"
                      label="Storage"
                      rules={{
                        required: "This field is required",
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormInput
                      name="cpus"
                      type="number"
                      id="cpus"
                      label="Cpus"
                      rules={{
                        required: "This field is required",
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </form>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box>
                <CodeTextEditor language={"yml"} minHeight={700} />
              </Box>
            </TabPanel>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setConfigDialogOpen(false)}>
            Close
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onSubmit)}>
            {getValues("id") ? `${isPromote ? "Promote" : "Edit"}` : "Provision"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} maxWidth="sm" open={openAssignDialog} onClose={() => setOpenAssignDialog(false)}>
        <DialogTitle>Assign Environment to User</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid container spacing={2} mt={1} pr={2} pb={2} sx={{ overflow: "auto" }}>
                <Grid item xs={8}>
                  <FormAsyncAutocomplete
                    name="organization"
                    id="organization"
                    label="Select organization"
                    getOptions={(searchTerm: string) =>
                      OrganizationService.getOrganizations({
                        count_per_page: 10,
                        page_no: 1,
                        filter: { org_name: searchTerm },
                        show_deleted: false,
                      })
                    }
                    labelKey="org_name"
                    valueKey="org_name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormAutocomplete
                    name="user_id"
                    id="user_id"
                    label="Select user"
                    options={[{ label: "User1" }, { label: "User2" }]}
                    labelKey="label"
                    valueKey="label"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenAssignDialog(false)}>
            Close
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onAssignSubmit)}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="This action will delete the selected environment"
        confirmationText="DELETE"
      />
      <ConfirmationDialog
        open={openSuspendConfirmation}
        setOpen={setOpenSuspendConfirmation}
        onProceed={() => {
          handleSuspendAndResume("suspend");
        }}
        title="Suspend"
        content={`This action will suspend the selected environment${selectedRow.length > 1 ? "s" : ""}.`}
        confirmationText="SUSPEND"
      />
      <ConfirmationDialog
        open={openResumeConfirmation}
        setOpen={setOpenResumeConfirmation}
        onProceed={() => {
          handleSuspendAndResume("resume");
        }}
        title="Resume"
        content="if you are sure you want to resume the selected environment?"
        confirmationText="RESUME"
      />
    </>
  );
};

export default Dataplanes;
