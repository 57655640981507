import { useEffect, useState } from "react";
import {
  Box,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  DialogContentText,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Card, CardContent, Grid } from "@mui/material";
import { Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import moment from "moment";
import useNotifications from "../../components/NotificationProvider";

import DatabaseService from "../../services/database.service";
import { FormInput } from "../../components/FormInput";
import { FormDatePicker } from "../../components/FormDatePicker";
import { FormCheckbox } from "../../components/FormCheckbox";

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 130 },
  { field: "createdAt", headerName: "Created At", width: 130 },
  { field: "status", headerName: "Status", width: 130 },
];

const rows = [
  { id: 1, name: "Backup1", createdAt: "04/28/2022", status: "Active" },
  { id: 2, name: "Backup2", createdAt: "04/28/2022", status: "Active" },
  { id: 3, name: "Backup3", createdAt: "04/28/2022", status: "Active" },
  { id: 4, name: "Backup4", createdAt: "04/28/2022", status: "Active" },
  { id: 5, name: "Backup5", createdAt: "04/28/2022", status: "Active" },
  { id: 6, name: "Backup6", createdAt: "04/28/2022", status: "Active" },
  { id: 7, name: "Backup7", createdAt: "04/28/2022", status: "Active" },
  { id: 8, name: "Backup8", createdAt: "04/28/2022", status: "Active" },
  { id: 9, name: "Backup9", createdAt: "04/28/2022", status: "Active" },
];

const mockData = {
  id: 2,
  name: "vm-name",
  status: "UP",
  dns: "dbbox-prod.eastus.azure.com",
  ipAddress: "132.54.2.3",
  configuration: {
    diskSize: 200,
    cpu: 4,
    memory: 16,
    availableDiskSize: 20,
    availableCpu: 2,
    availableMemory: 8,
  },
};

const DatabaseDetails = () => {
  const { t } = useTranslation("common");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({});
  const [DBDetail, setDBDetails] = useState<any>({});
  const [openResamplingForm, setOpenResamplingForm] = useState(false);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState(false);
  const [openRestoreConfirmation, setOpenRestoreConfirmation] = useState(false);
  const [openBackupConfirmation, setOpenBackupConfirmation] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [rowsState, setRowsState] = useState<any>({
    page: 0,
    pageSize: 5,
  });
  const navigate = useNavigate();
  const { setNotification } = useNotifications();
  let { dbName } = useParams();
  const watchConfirm = watch("confirm", "");

  useEffect(() => {
    (async () => {
      try {
        const databases = await DatabaseService.getDBDetail(dbName);
        console.log("databases---", databases);
        setDBDetails(mockData);
      } catch (e) {
        console.error("----", e);
        setDBDetails(mockData);
      }
    })();
  }, [dbName]);

  const handleRestore = async () => {
    try {
      const data = { dbName, selectedRow };
      const result = await DatabaseService.restoreBackup(data);
      console.log("result---", result);
      setOpenRestoreConfirmation(false);
      setNotification({ message: "Success", open: true });
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
      console.error("----", e);
    }
  };

  const handleRemove = async () => {
    try {
      const data = { dbName, selectedRow };
      const result = await DatabaseService.removeBackup(data);
      console.log("result---", result);
      setOpenRemoveConfirmation(false);
      setNotification({ message: "Success", open: true });
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
      console.error("----", e);
    }
  };

  const handleBackup = async () => {
    try {
      const result = await DatabaseService.createBackup({ dbName });
      console.log("result---", result);
      setOpenBackupConfirmation(false);
      setNotification({ message: "Success", open: true });
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
      console.error("----", e);
    }
  };

  const handleNavigation = (route: string) => {
    navigate(route);
  };

  const onResamplingSubmit = async (values: any) => {
    try {
      const result = await DatabaseService.resampleDatabase(values);
      console.log("values---", values, result);
      setOpenResamplingForm(false);
      setNotification({ message: "Success", open: true });
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
      console.error("----", e);
    }
  };

  return (
    <>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ marginBottom: "20px" }}>
        <Link
          onClick={() => {
            handleNavigation("/Database");
          }}
          sx={{ cursor: "pointer" }}
          color="#777"
        >
          {t("common:dashboard.Dashboard")}
        </Link>
        <Typography
          onClick={() => {
            handleNavigation(`/Database/${DBDetail.name}`);
          }}
          color="#777"
        >
          {DBDetail.name}
        </Typography>
      </Breadcrumbs>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <Typography gutterBottom variant="h4">
            {DBDetail.name}
          </Typography>
          <Card sx={{ marginBottom: "16px" }}>
            <CardHeader variant="h6" title="Summary" />
            <Divider />
            <CardContent sx={{ paddingBottom: "16px !important" }}>
              <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
                <Grid item xs={4} sx={{ borderRight: "1px solid" }}>
                  <Typography gutterBottom variant="body1">
                    DB Identifier
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {DBDetail.name}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ borderRight: "1px solid" }}>
                  <Typography gutterBottom variant="body1">
                    DNS
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {DBDetail.dns}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="body1">
                    IP
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {DBDetail.ipAddress}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4} sx={{ borderRight: "1px solid" }}>
                  <Typography gutterBottom variant="body1">
                    CPU
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {DBDetail.configuration?.cpu}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ borderRight: "1px solid" }}>
                  <Typography gutterBottom variant="body1">
                    Memory
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {DBDetail.configuration?.memory}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="body1">
                    location
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {DBDetail.location}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              variant="h6"
              action={
                <>
                  <Button
                    onClick={() => {
                      setOpenRestoreConfirmation(true);
                    }}
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    disabled={selectedRow.length !== 1}
                  >
                    Restore
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenRemoveConfirmation(true);
                    }}
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    disabled={!selectedRow.length}
                  >
                    Remove
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenBackupConfirmation(true);
                    }}
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                  >
                    Backup
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenResamplingForm(true);
                    }}
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                  >
                    Resample
                  </Button>
                </>
              }
              title="Backup and Actions"
            />
            <Divider />
            <CardContent sx={{ paddingBottom: "16px !important" }}>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  {...rowsState}
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = rows.filter((row) => {
                      return selectedIDs.has(row.id);
                    });
                    console.log(selectedRowData);
                    setSelectedRow(selectedRowData);
                  }}
                  onPageChange={(page) => setRowsState((prev: any) => ({ ...prev, page }))}
                />
              </div>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <Dialog fullWidth={true} maxWidth="md" open={openResamplingForm} onClose={() => setOpenResamplingForm(false)}>
        <DialogTitle>Resample</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid container spacing={2} mt={1} pr={2} pb={2} sx={{ overflow: "auto" }}>
                <Grid item xs={4}>
                  <FormDatePicker
                    name="startDate"
                    id="startDate"
                    label="Start date"
                    type="datetime-local"
                    defaultValue={moment().format("yyyy-MM-DDTHH:mm")}
                    rules={{
                      required: "This field is required",
                      validate: {
                        futureDate: (v: string) => (moment().diff(v) > 0 ? true : "Date can't be future"),
                        lessThanDate: (v: string) =>
                          moment(v).diff(getValues("endDate")) <= 0 ? true : "Start date can't be after End date",
                      },
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormDatePicker
                    name="endDate"
                    id="endDate"
                    label="End date"
                    type="datetime-local"
                    defaultValue={moment().format("yyyy-MM-DDTHH:mm")}
                    rules={{
                      required: "This field is required",
                      validate: {
                        futureDate: (v: string) => (moment().diff(v) > 0 ? true : "Date can't be future"),
                      },
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name="deleteExistingData"
                    id="deleteExistingData"
                    label="Delete existing data first?"
                    rules={{}}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenResamplingForm(false)}>
            Close
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onResamplingSubmit)}>
            Resample
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRestoreConfirmation}
        onClose={() => {
          setOpenRestoreConfirmation(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Restore</DialogTitle>
        <DialogContent>
          <DialogContentText component={"div"} id="alert-dialog-description">
            <Typography>This action will restore the selected backup</Typography>
            <Typography my={2}>Type "RESTORE" to proceed.</Typography>
            <Box sx={{ width: "300px" }}>
              <FormInput
                name="confirm"
                id="confirm"
                label="Confirmation"
                type="text"
                rules={{
                  required: "This field is required",
                }}
                control={control}
                register={register}
                errors={errors}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenRestoreConfirmation(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRestore();
            }}
            variant="contained"
            disabled={watchConfirm !== "RESTORE"}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRemoveConfirmation}
        onClose={() => {
          setOpenRemoveConfirmation(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove</DialogTitle>
        <DialogContent>
          <DialogContentText component={"div"} id="alert-dialog-description">
            <Typography>
              {`This action will permanently remove the selected backup${selectedRow.length > 1 ? "s" : ""}.`}
            </Typography>
            <Typography my={2}>Type "REMOVE" to proceed.</Typography>
            <Box sx={{ width: "300px" }}>
              <FormInput
                name="confirm"
                id="confirm"
                label="Confirmation"
                type="text"
                rules={{
                  required: "This field is required",
                }}
                control={control}
                register={register}
                errors={errors}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenRemoveConfirmation(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemove();
            }}
            variant="contained"
            disabled={watchConfirm !== "REMOVE"}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openBackupConfirmation}
        onClose={() => {
          setOpenBackupConfirmation(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Backup</DialogTitle>
        <DialogContent>
          <DialogContentText component={"div"} id="alert-dialog-description">
            <Typography>If you are sure you want to create a backup now?</Typography>
            <Typography my={2}>Type "BACKUP" to proceed.</Typography>
            <Box sx={{ width: "300px" }}>
              <FormInput
                name="confirm"
                id="confirm"
                label="Confirmation"
                type="text"
                rules={{
                  required: "This field is required",
                }}
                control={control}
                register={register}
                errors={errors}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenBackupConfirmation(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleBackup();
            }}
            variant="contained"
            disabled={watchConfirm !== "BACKUP"}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DatabaseDetails;
