
export const SERVICE_BASE_URLS = {
  ACCOUNTS: '/accounts-v2',
  DB_MANAGER: '/database-manager',
  ACCOUNTS_CUSTOMIZE: '/user-customize/accounts-customize',
  SETUP_DATA_SERVICE: '/setup-data',
}

export const DEFAULT_IDS = {
  SANDBOX_TENANT: process.env.REACT_APP_SANDBOX_TENANT_ID || "", // "a727b34d-1e73-4d74-86f3-1932153e51ad",
  SANDBOX_LOCATION: process.env.REACT_APP_SANDBOX_LOCATION_ID || "" //"84ac0248-3732-458d-b9e2-8ef3fad4dc21"
}

export const environmentStatusList = [
  {label: "Starting", value: "starting"},
  {label: "Running", value: "running"},
  {label: "Deleting", value: "deleting"},
  {label: "Suspended", value: "suspended"},
  {label: "Resumed", value: "resumed"}
];

export const environmentDBTiers = [
  { label: "Isolated", value: "isolated" },
  { label: "Shared", value: "shared" },
];

export const batchMock = [
  {
    id: "1",
    userid: "1",
    tenantid: "1",
    location_name: "MyPlant",
    location_tz: "CT",
    storage: "2",
    cpus: "4",
    status: "Active",
    tenant: "1",
    env: "1",
  },
  {
    id: "2",
    userid: "2",
    tenantid: "1",
    location_name: "MyPlant",
    location_tz: "CT",
    storage: "2",
    cpus: "4",
    status: "Active",
    tenant: "2",
    env: "2",
  },
  {
    id: "3",
    userid: "3",
    tenantid: "1",
    location_name: "MyPlant",
    location_tz: "CT",
    storage: "2",
    cpus: "4",
    status: "Active",
    tenant: "3",
    env: "3",
  },
  {
    id: "4",
    userid: "4",
    tenantid: "1",
    location_name: "MyPlant",
    location_tz: "CT",
    storage: "2",
    cpus: "4",
    status: "Active",
    tenant: "4",
    env: "4",
  },
  {
    id: "5",
    userid: "5",
    tenantid: "1",
    location_name: "MyPlant",
    location_tz: "CT",
    storage: "2",
    cpus: "4",
    status: "Active",
    tenant: "5",
    env: "5",
  },
];

export const pagesMock = ["Monitor", "Analyze", "All"];

export const objectsMock = ["CustomView", "Annotations", "Invite", "metadata", "time-series"];

export const operationsMock = [
  {
    operation_name: "READ",
  },
  {
    operation_name: "CREATE",
  },
  {
    operation_name: "UPDATE",
  },
  {
    operation_name: "DELETE",
  },
];

export const permissionsMock = [
  {
    deleted: false,
    last_updated: "2022-08-03T10:11:21.029955Z",
    operations: {
      object: "CustomView",
      operations: ["CREATE", "UPDATE"],
      page: "Monitor",
    },
    permission_desc: "Permission 1",
    permission_id: 1,
  },
  {
    deleted: false,
    last_updated: "2022-08-03T10:11:21.029955Z",
    operations: {
      object: "CustomView",
      operations: ["CREATE", "UPDATE"],
      page: "Monitor",
    },
    permission_desc: "Permission 2",
    permission_id: 2,
  },
  {
    deleted: false,
    last_updated: "2022-08-03T10:11:21.029955Z",
    operations: {
      object: "CustomView",
      operations: ["CREATE", "UPDATE"],
      page: "Monitor",
    },
    permission_desc: "Permission 3",
    permission_id: 3,
  },
];

export const defaultTenantPreferences = {
  analyze_time_thresholds: [
    {
      interval_in_seconds: 259200,
      window_time_in_minutes: 1576800,
    },
    {
      interval_in_seconds: 86400,
      window_time_in_minutes: 525600,
    },
    {
      interval_in_seconds: 21600,
      window_time_in_minutes: 129600,
    },
    {
      interval_in_seconds: 7200,
      window_time_in_minutes: 43200,
    },
    {
      interval_in_seconds: 1800,
      window_time_in_minutes: 10080,
    },
    {
      interval_in_seconds: 300,
      window_time_in_minutes: 1440,
    },
    {
      interval_in_seconds: 120,
      window_time_in_minutes: 720,
    },
    {
      interval_in_seconds: 60,
      window_time_in_minutes: 240,
    },
    {
      interval_in_seconds: 0,
      window_time_in_minutes: 60,
    },
    {
      interval_in_seconds: 0,
      window_time_in_minutes: 0,
    },
  ],
  monitor_anomaly_thresholds: {
    high: 7,
    low: 1,
    medium: 4,
    none: 0,
  },
  monitor_error_thresholds: [
    {
      error: "General",
      threshold: -1,
    },
    {
      error: "No Data",
      threshold: -2,
    },
    {
      error: "Misc",
      threshold: -10000000,
    },
  ],
  monitor_time_thresholds: [
    {
      interval_in_seconds: 604800,
      window_time_in_minutes: 1576800,
    },
    {
      interval_in_seconds: 172800,
      window_time_in_minutes: 525600,
    },
    {
      interval_in_seconds: 86400,
      window_time_in_minutes: 129600,
    },
    {
      interval_in_seconds: 21600,
      window_time_in_minutes: 43200,
    },
    {
      interval_in_seconds: 3600,
      window_time_in_minutes: 10080,
    },
    {
      interval_in_seconds: 900,
      window_time_in_minutes: 1440,
    },
    {
      interval_in_seconds: 300,
      window_time_in_minutes: 480,
    },
    {
      interval_in_seconds: 120,
      window_time_in_minutes: 240,
    },
    {
      interval_in_seconds: 60,
      window_time_in_minutes: 0,
    },
  ],
  resampling_interval_sec: 60,
};
