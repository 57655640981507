import { useEffect, useState } from "react";
import { Button, Card, CardHeader, CardContent, Box, Grid, Typography, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridCallbackDetails, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import AccountService from "../../services/account.service";
import { FormInput } from "../../components/FormInput";
import useNotifications from "../../components/NotificationProvider";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { CustomTableToolbar } from "../../components/CustomTableToolbar";
import OnboardForm from "../../components/onboardForm";
import { IRowFilter, ITableRowsState } from "../../types/app/common.type";
import { IAccount } from "../../types/api/accounts.type";

const Accounts = () => {
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [openAccountDialog, setAccountDialogOpen] = useState(false);
  const [openOnboardDialog, setOnboardDialogOpen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [rowsState, setRowsState] = useState<ITableRowsState>({
    page: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState<number>(0);
  const [rowFilter, setRowFilter] = useState<IRowFilter>({} as IRowFilter);
  const [tableRows, setTableRows] = useState<IAccount[]>([]);
  const [selectedRow, setSelectedRow] = useState<IAccount[]>([]);
  const [searchText, setSearchText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const { setNotification } = useNotifications();

  const columns: GridColDef[] = [
    { field: "account_id", headerName: "Account ID", flex: 1 },
    { field: "account_name", headerName: "Name", flex: 1 },
    { field: "country", headerName: "Country", flex: 1 },
    { field: "state", headerName: "State", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "subdomain", headerName: "Sub domain", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (params.row.deleted) {
          return <></>;
        }
        return (
          <>
            <EditIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                setAccountDialogOpen(true);
                reset({ ...params.row });
              }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                setSelectedRow([{ ...params.row }]);
                setOpenDeleteConfirmation(true);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsState, rowFilter, showDeleted]);

  useEffect(() => {
    if (searchText) {
      const delayDebounceFn = setTimeout(() => {
        setRowFilter({ account_name: searchText });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getAccounts = async () => {
    try {
      const result = await AccountService.getAccounts({
        count_per_page: rowsState.pageSize,
        page_no: rowsState.page + 1,
        filter: rowFilter,
        show_deleted: showDeleted,
      });
      console.log("result", result.data);
      setTotalRows(result.data?.total);
      setTableRows(result.data?.results);
    } catch (e) {
      console.error("----", e);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      const account_id = values.account_id;
      if (account_id) {
        delete values.account_id;
        await AccountService.editAccount(values, account_id);
      } else {
        await AccountService.createAccount(values);
      }
      setAccountDialogOpen(false);
      setNotification({
        message: `Account ${account_id ? "updated" : "created"}successfully`,
        open: true,
      });
      getAccounts();
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const handleDelete = async () => {
    try {
      await AccountService.deleteAccount(selectedRow[0].account_uuid as string);
      setOpenDeleteConfirmation(false);
      setNotification({ message: "Account deleted successfully", open: true });
      getAccounts();
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const onFilterChange = (model: GridFilterModel, details: GridCallbackDetails) => {
    console.log("filter data---", model, details);
    setRowFilter({ [model.items[0].columnField]: model.items[0].value });
  };

  const handleSearchChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setRowFilter({});
  };

  const onOnboardSubmit = async (values: any) => {
    try {
      values.geo = {};
      await AccountService.onboardAccount(values);
      setOnboardDialogOpen(false);
      setNotification({
        message: `Account created successfully`,
        open: true,
      });
      getAccounts();
      reset({});
    } catch (e) {
      console.error("Error", e);
      setNotification({
        message: `Error occurred`,
        type: "error",
        open: true,
      });
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardHeader
          action={
            <>
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => {
                  setOnboardDialogOpen(true);
                }}
              >
                Onboard
              </Button>
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                onClick={() => {
                  setAccountDialogOpen(true);
                }}
              >
                Create
              </Button>
            </>
          }
          title={t("common:accounts.title")}
        />
        <CardContent
          sx={{
            paddingBottom: "16px !important",
            "& .deleted-row": {
              backgroundColor: "#4c3939",
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={tableRows}
            columns={columns}
            getRowId={(row) => row.account_id}
            rowsPerPageOptions={[rowsState.pageSize]}
            rowCount={totalRows}
            disableSelectionOnClick
            getRowClassName={(params) => (params.row.deleted ? "deleted-row" : "")}
            paginationMode="server"
            // sortingMode="server"
            // onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            {...rowsState}
            onPageChange={(page) => setRowsState((prev: ITableRowsState) => ({ ...prev, page }))}
            components={{
              Toolbar: CustomTableToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                setFilterButtonEl,
                searchValue: searchText,
                onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearchChange(event.target.value);
                },
                showDeleted: showDeleted,
                onShowDeletedChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event.target.checked);
                  setShowDeleted(event.target.checked);
                },
                clearSearch: () => handleClearSearch(),
              },
            }}
          />
        </CardContent>
      </Card>
      <Dialog fullWidth={true} maxWidth="md" open={openAccountDialog} onClose={() => setAccountDialogOpen(false)}>
        <DialogTitle>{`${getValues("account_id") ? "Edit" : "Create"} account`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid container spacing={2} mt={1} pr={2} pb={2} sx={{ overflow: "auto" }}>
                <Grid item xs={6}>
                  <FormInput
                    name="account_name"
                    type="string"
                    id="account_name"
                    label="Account name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="address_line_1"
                    type="string"
                    id="address_line_1"
                    label="Address Line 1"
                    rules={{
                      required: "This field is required",
                    }}
                    extraFieldData={{
                      multiline: true,
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="address_line_2"
                    type="string"
                    id="address_line_2"
                    label="Address Line 2"
                    rules={{
                      required: "This field is required",
                    }}
                    extraFieldData={{
                      multiline: true,
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="city"
                    type="string"
                    id="city"
                    label="City"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="state"
                    type="string"
                    id="state"
                    label="State"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="country"
                    type="string"
                    id="country"
                    label="Country"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="postal"
                    type="number"
                    id="postal"
                    label="Postal"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
                    Domain config
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="subdomain"
                    type="string"
                    id="subdomain"
                    label="Sub domain"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setAccountDialogOpen(false)}>
            Cancel
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} maxWidth="md" open={openOnboardDialog} onClose={() => setOnboardDialogOpen(false)}>
        <DialogTitle>Account Onboard</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <OnboardForm control={control} register={register} errors={errors} />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOnboardDialogOpen(false)}>
            Cancel
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onOnboardSubmit)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="If you are sure you want to delete the selected account?"
        confirmationText={selectedRow[0]?.account_name}
      />
    </>
  );
};

export default Accounts;
