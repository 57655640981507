import React, { useEffect } from "react";
import Ably from "ably";
import { getAblyToken } from "../../services/ably.service";

interface AblyProps {
  ablyClient: Ably.Realtime | undefined;
  setAblyClient: (client: Ably.Realtime | undefined) => void;
}

export const AblyComponent: React.FC<AblyProps> = ({
  ablyClient,
  setAblyClient,
}) => {
  useEffect(() => {
    const initializeAbly = async () => {
      try {
        // Only initialize Ably if it's not already set
        if (ablyClient) {
          return;
        }

        const ablyClientInstance = new Ably.Realtime({
          authCallback: async (tokenParams, callback) => {
            try {
              // Fetch token on each auth request
              const tokenResponse = await getAblyToken();
              setAblyClient(ablyClientInstance);
              ablyClientInstance.connection.on("connected", () => {
              });
              callback(null, tokenResponse.data as Ably.TokenDetails);
            } catch (error) {
              console.error(`Ably Error while requesting auth token: ${error}`);
              callback(error as string, null);
            }
          },
        });
      } catch (error) {
        console.error(`Failed to get initial Ably token: ${error}`);
      }
    };

    initializeAbly();
  }, [ablyClient, setAblyClient]);

  return <></>; // No UI needed for this component
};
