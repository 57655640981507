import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

interface SearchParams {
  searchText?: string;
  processId?: number;
}


const getFamilyDeployments = (options: object) => {
  return api.get(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-deployments`,
    options
  );
};

const getProcesses = (options: object) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/process`, options);
};

const createFamilyDeployment = (data: object, options: object) => {
  return api.post(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-deployment`,
    data,
    options
  );
};

const updateFamilyDeployment = (params: any, data: object, options: object) => {
  return api.put(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/family-deployment/${params.family_id}/${params.process_id}/${params.ensemble_id}`,
    data,
    options
  );
};


const getEnsembleListBySearch = (options: object, params: SearchParams) => {
  const queryParams: string[] = [];

  if (params.searchText) {
    queryParams.push(`search_text=${params.searchText}`);
  }
  if (params.processId) {
    queryParams.push(`process_id=${params.processId}`);
  }

  const queryString = queryParams.length > 0 ? queryParams.join('&') : '';

  return api.get(
    `${SERVICE_BASE_URLS.ACCOUNTS_CUSTOMIZE}/process-ensemble/search${queryString ? `?${queryString}` : ''}`,
    options
  );
};

const FamilyDeploymentService = {
  getFamilyDeployments,
  getProcesses,
  createFamilyDeployment,
  updateFamilyDeployment,
  getEnsembleListBySearch,
};

export default FamilyDeploymentService;
