import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button, Card, CardHeader, CardContent, Box, Grid, Divider, Breadcrumbs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridCallbackDetails, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { FormInput } from "../../components/FormInput";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { CustomTableToolbar } from "../../components/CustomTableToolbar";
import useNotifications from "../../components/NotificationProvider";
import { FormAutocomplete } from "../../components/FormAutoComplete";
import PermissionService from "../../services/permission.service";
import { objectsMock, operationsMock, pagesMock } from "../../global/constants";
import { constructPermissionDetail } from "../../utils/utils";
import { IRowFilter, ITableRowsState } from "../../types/app/common.type";
import { IPermission } from "../../types/api/permission.type";

export default function Permissions() {
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [rowsState, setRowsState] = useState<ITableRowsState>({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState<number>(0);
  const [rowFilter, setRowFilter] = useState<IRowFilter>({});
  const [tableRows, setTableRows] = useState<IPermission[]>([]);
  const [selectedRow, setSelectedRow] = useState<IPermission[]>([]);
  const [searchText, setSearchText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const { setNotification } = useNotifications();

  const columns: GridColDef[] = [
    { field: "permission_id", headerName: "Permission ID", flex: 1 },
    { field: "permission_desc", headerName: "Permission description", flex: 1 },
    {
      field: "page",
      headerName: "Page",
      flex: 1,
      renderCell: (params) => <>{params.row.operations?.page}</>,
    },
    {
      field: "object",
      headerName: "Object",
      flex: 1,
      renderCell: (params) => <>{params.row.operations?.object}</>,
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 2,
      renderCell: (params) => <>{params.row.operations?.operations.join(", ")}</>,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (params.row.deleted) {
          return <></>;
        }
        return (
          <>
            <EditIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                console.log("edit---", params);
                setOpenPermissionDialog(true);
                handleEditClick({ ...params.row });
              }}
            />
            <DeleteIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                console.log("delete---", params);
                setSelectedRow([{ ...params.row }]);
                setOpenDeleteConfirmation(true);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!openPermissionDialog) {
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPermissionDialog]);

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsState, rowFilter, showDeleted]);

  useEffect(() => {
    if (searchText) {
      const delayDebounceFn = setTimeout(() => {
        setRowFilter({ permission_name: searchText });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleEditClick = async (permissionData: IPermission) => {
    try {
      const permission = await (await PermissionService.getPermissionByID(permissionData.permission_id as number)).data;
      permission.operations.operations = permission.operations.operations?.map((operation: any) => {
        return { operation_name: operation };
      });
      reset({ ...permission });
    } catch (error) {}
  };

  const getPermissions = async () => {
    try {
      const result = await PermissionService.getPermissions({
        count_per_page: rowsState.pageSize,
        page_no: rowsState.page + 1,
        filter: rowFilter,
        show_deleted: showDeleted,
      });
      setTotalRows(result.data?.total);
      setTableRows(result.data?.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      let permission_id = values.permission_id;
      const constructedData = constructPermissionDetail(values);
      if (permission_id) {
        await PermissionService.editPermission(constructedData, permission_id);
      } else {
        await PermissionService.createPermission(constructedData);
      }
      setOpenPermissionDialog(false);
      setNotification({
        message: `Permission ${permission_id ? "updated" : "created"} successfully`,
        open: true,
      });
      getPermissions();
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("error", e);
    }
  };

  const handleDelete = async () => {
    try {
      await PermissionService.deletePermission(selectedRow[0].permission_id as number);
      setOpenDeleteConfirmation(false);
      setNotification({ message: "Permission deleted successfully", open: true });
      getPermissions();
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("----", e);
    }
  };

  const onFilterChange = (model: GridFilterModel, details: GridCallbackDetails) => {
    console.log("filter data---", model, details);
    setRowFilter({ [model.items[0].columnField]: model.items[0].value });
  };

  const handleSearchChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setRowFilter({});
  };

  return (
    <>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ marginBottom: "20px" }}>
        <Typography color="#777">Permissions</Typography>
      </Breadcrumbs>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardHeader
          action={
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              onClick={() => {
                setOpenPermissionDialog(true);
              }}
            >
              Create
            </Button>
          }
          title={t("common:permissions.title")}
        />
        <CardContent
          sx={{
            paddingBottom: "16px !important",
            "& .deleted-row": {
              backgroundColor: "#4c3939",
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={tableRows}
            columns={columns}
            getRowId={(row) => row.permission_id}
            rowsPerPageOptions={[rowsState.pageSize]}
            rowCount={totalRows}
            disableSelectionOnClick
            getRowClassName={(params) => (params.row.deleted ? "deleted-row" : "")}
            paginationMode="server"
            // sortingMode="server"
            // onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            {...rowsState}
            onPageChange={(page) => setRowsState((prev: ITableRowsState) => ({ ...prev, page }))}
            components={{
              Toolbar: CustomTableToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                setFilterButtonEl,
                searchValue: searchText,
                onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearchChange(event.target.value);
                },
                showDeleted: showDeleted,
                onShowDeletedChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event.target.checked);
                  setShowDeleted(event.target.checked);
                },
                clearSearch: () => handleClearSearch(),
                showDeletedCheckbox: false,
              },
            }}
          />
        </CardContent>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openPermissionDialog}
        onClose={() => {
          setOpenPermissionDialog(false);
        }}
      >
        <DialogTitle>{`${getValues("permission_id") ? "Edit" : "Create"} permission`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
                    Permission detail
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    name="permission_desc"
                    type="text"
                    id="permission_desc"
                    label="Permission description"
                    required
                    rules={{
                      required: "This field is required",
                    }}
                    extraFieldData={{
                      multiline: true,
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name="operations.page"
                    id="operations.page"
                    label="Page"
                    options={pagesMock}
                    labelKey="page_name"
                    valueKey="page_name"
                    creatable
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name="operations.object"
                    id="operations.object"
                    label="Object"
                    options={objectsMock}
                    labelKey="object_name"
                    valueKey="object_name"
                    creatable
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name="operations.operations"
                    id="operations.operations"
                    label="Operations"
                    options={operationsMock}
                    labelKey="operation_name"
                    valueKey="operation_name"
                    multiple
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenPermissionDialog(false)}>
            Cancel
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={handleDelete}
        title="Delete"
        content="If you are sure you want to delete the selected permission?"
        confirmationText={selectedRow[0]?.permission_desc}
      />
    </>
  );
}
