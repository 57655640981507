import React, { useCallback, useEffect, useState } from "react";
import Link from "@mui/material/Link";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Badge from "@mui/material/Badge";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Divider,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  CardHeader,
  Grid,
  DialogActions,
  Checkbox,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Card, CardContent } from "@mui/material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import DatabaseService from "../../services/database.service";
import { FormInput } from "../../components/FormInput";
import { FormCheckbox } from "../../components/FormCheckbox";
import useNotifications from "../../components/NotificationProvider";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import useLoader from "../../components/LoaderProvider";

const mockdata = [
  {
    id: 1,
    name: "vm-name",
    status: "UP",
    dns: "dbbox-prod.eastus.azure.com",
    ipAddress: "132.54.2.3",
    configuration: {
      diskSize: 200,
      cpu: 4,
      memory: 16,
      availableDiskSize: 100,
      availableCpu: 3,
      availableMemory: 4,
    },
  },
  {
    id: 2,
    name: "vm-name",
    status: "UP",
    dns: "dbbox-prod.eastus.azure.com",
    ipAddress: "132.54.2.3",
    configuration: {
      diskSize: 200,
      cpu: 4,
      memory: 16,
      availableDiskSize: 20,
      availableCpu: 2,
      availableMemory: 8,
    },
  },
];

const Database = () => {
  const { setLoading } = useLoader();
  const { t } = useTranslation("common");
  const [showLogView, toggleLogView] = useState(false);
  const [openDBCreationForm, setOpenDBCreationForm] = useState(false);
  const [openDBUpgradeForm, setOpenDBUpgradeForm] = useState(false);
  const [imageInputValue, setImageInputValue] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [data, setData] = useState<any>([]);
  const [logData] = useState<{ dns: string; logs: Array<any> }>({
    dns: "",
    logs: [],
  });
  const watchUseExistingDB = watch("useExistingDB", false);
  const { setNotification } = useNotifications();
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false)

  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleRowSelect = (envId: number) => {
    const isEnvIdExist = selectedRows.includes(envId);
    let newSelected: number[] = [];

    if (!isEnvIdExist) {
      newSelected = [...selectedRows, envId];
    } else {
      newSelected = selectedRows.filter((id) => id !== envId);
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAllToggle = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const newSelectedRows = newSelectAll ? data.map((row: any) => row.environment_id) : [];
    setSelectedRows(newSelectedRows);
  };

  const handleBulkDBUpgradeSubmit = async () => {
    const payload = {
      db_upgrade_env: {
        image_name: imageInputValue
      },
      env_ids: selectedRows
    }

    try {
      if (selectedTab === 0) {
        await DatabaseService.bulkDBUpgrade(payload);
      } else {
        await DatabaseService.bulkRuleEngineUpgrade(payload);
      }
      setOpenDBUpgradeForm(false);
      setNotification({ message: "Success", open: true });
      getDatabases();
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
    }

  }

  const getDatabases = useCallback(async ()=>{
    try {
      setLoading(true);
      const databases = await DatabaseService.getDatabases();
      setData(databases.data.envs);
    } catch (e) {
      console.error("----", e);
      setData(mockdata);
    } finally {
      setLoading(false);
    }
  },[setLoading])

  useEffect(() => {
      getDatabases();
  }, [getDatabases]);

  const handleNavigation = (name: string) => {
    navigate(`/Database/${name}`);
  };

  const onSubmit = async (values: any) => {
    try {
      console.log("values---", values);
      setNotification({ message: "Success", open: true });
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
      console.error("----", e);
    }
  };


  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleEnvironmentDelete = () => {
    setOpenDeleteConfirmation(true)
  }

  const proceedToDeleteEnvironment = async () => {
    try {
      setOpenDeleteConfirmation(false)
      await DatabaseService.deleteDBEnvironment(selectedRows[0]);
      setNotification({ message: "Environment deleted successfully", open: true });
      getDatabases();
    } catch (e) {
      setNotification({ message: "Error", open: true, type: "error" });
      console.log("Error while delete environment --", e)
    }
  }

  const getConfirmationText = () => {
    if (selectedRows.length === 1) {
      const currentSelectedEnvId = selectedRows[0]
      const currentSelectedEnvInfo = data.filter((row: any) => row.environment_id === currentSelectedEnvId);
      return currentSelectedEnvInfo[0].environment_id + '-' + currentSelectedEnvInfo[0].environment_name
    }
    return ''
  }

  const confirmationText = getConfirmationText();

  return (
    <>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{ marginBottom: "20px" }}
      >
        <Typography color="#777">{t("common:dashboard.Dashboard")}</Typography>
      </Breadcrumbs>
      <Card sx={{ backgroundColor: "#2A2B31", maxWidth: "1450px" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <CardHeader
            action={[
              <Button
                disabled={selectedRows.length !== 1}
                sx={{ marginRight: "10px" }}
                onClick={() => handleEnvironmentDelete()}
                variant="contained"
              >
                Delete
              </Button>,

              <Button
                disabled={selectedRows.length === 0}
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  setOpenDBUpgradeForm(true);
                  setImageInputValue('')
                }}
                variant="contained"
              >
                Upgrade
              </Button>,
              <Button
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  setOpenDBCreationForm(true);
                }}
                disabled={true} // Temporarily disabled
                variant="contained"
              >
                Create
              </Button>
            ]}
            title={t("common:database.title")}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1050 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" padding="checkbox">
                    <Checkbox
                      checked={selectAll}
                      indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
                      onChange={handleSelectAllToggle}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Environment</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Tenant</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Location</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Organization</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Account</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Image</TableCell>
                </TableRow>
                {data.length > 0 ? (
                  data.map((row: any) => (
                    <TableRow
                      key={row.environment_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.includes(row.environment_id)}
                          onChange={() => handleRowSelect(row.environment_id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link
                          onClick={() => {
                            handleNavigation(row.environment_name);
                          }}
                          component="h4"
                          sx={{ cursor: "pointer" }}
                        >
                          {<Badge
                            color={"success"}
                            variant="dot"
                            sx={{ marginRight: "16px !important" }}
                          />}
                          {row.environment_id + '-' + row.environment_name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          display="block"
                          color={row.prod_env ? "#7AC231" : "#9E9E9E"}
                        >
                          {row.tenant_name}{" "}
                          <ContentCopyIcon
                            fontSize="small"
                            sx={{
                              color: "#fff",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(row.tenant_name);
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Link href={row.location_name} color="#79bcff">
                          {row.location_name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <Link href={row.organization_name} color="#79bcff">
                          {row.organization_name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <Link href={row.account_name} color="#79bcff">
                          {row.account_name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <Link href={row.image_name} color="#79bcff">
                          {row.image_name}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No records found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Drawer
            anchor={"right"}
            open={showLogView}
            onClose={() => toggleLogView(false)}
            PaperProps={{
              sx: { width: "40%" },
            }}
          >
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Typography
                variant="caption"
                display="block"
                color="#888"
                sx={{ fontWeight: "bold", mb: 3 }}
              >
                DNS:{" "}
                <Typography
                  variant="body1"
                  component="div"
                  color="#7AC231"
                  sx={{ fontSize: "14px", marginBottom: "5px" }}
                >
                  {logData.dns}
                </Typography>
              </Typography>
              {logData.logs.map((row: any) => {
                return (
                  <>
                    <Typography
                      variant="body1"
                      component="div"
                      color="#7AC231"
                      sx={{ fontSize: "14px", marginBottom: "5px" }}
                    >
                      {row.timestamp}:{" "}
                      <Typography
                        variant="body1"
                        component="span"
                        color="#fff"
                        sx={{ fontSize: "inherit" }}
                      >
                        {row.logLevel}: {row.log}
                      </Typography>
                    </Typography>
                  </>
                );
              })}
            </Box>
          </Drawer>
        </CardContent>
      </Card>
      
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onProceed={proceedToDeleteEnvironment}
        title="Delete"
        content="If you are sure you want to delete the selected account"
        confirmationText={confirmationText}
      />

      {/* Dialog box for db image upgrade for env */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openDBUpgradeForm}
        onClose={() => setOpenDBUpgradeForm(false)}
      >
        <DialogTitle>Upgrade Image</DialogTitle>
        <Box sx={{ padding: "5px" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="image-upgrade-tabs"
          >
            <Tab label="Database" />
            <Tab label="Rule Engine" />
          </Tabs>
        </Box>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={`Enter ${selectedTab === 0 ? "DB" : "Rule Engine"} Image ID`}
            type="text"
            fullWidth
            value={imageInputValue}
            onChange={(e: any) => {
              setImageInputValue(e.target.value);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenDBUpgradeForm(false)}>
            Close
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            disabled={imageInputValue.trim() === ''}
            onClick={handleBulkDBUpgradeSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openDBCreationForm}
        onClose={() => setOpenDBCreationForm(false)}
      >
        <DialogTitle>Create Database</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={4}>
                  <FormInput
                    name="name"
                    type="string"
                    id="name"
                    label="Name"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="location"
                    type="string"
                    id="location"
                    label="Location"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="cpu"
                    type="number"
                    id="cpu"
                    label="Cpu"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="memory"
                    type="number"
                    id="memory"
                    label="Memory"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="dns"
                    type="string"
                    id="dns"
                    label="Dns"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name="useExistingSchema"
                    id="useExistingSchema"
                    label="Use existing schema"
                    rules={{}}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name="useExistingDB"
                    id="useExistingDB"
                    label="Use existing database"
                    rules={{}}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                {watchUseExistingDB && (
                  <>
                    <Grid item xs={4}>
                      <FormInput
                        name="dbpath"
                        type="text"
                        id="dbpath"
                        label="DB path"
                        rules={{
                          required: "This field is required",
                        }}
                        control={control}
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenDBCreationForm(false)}>
            Close
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Provision
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Database;
