import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Controller } from "react-hook-form";
import { FieldProps } from "./types";
import { styled } from "@mui/material/styles";

export interface FormChipContainerProps extends FieldProps {
  sx: any;
  chips: Array<any>;
  handleChipDelete: (index: number, key_id: string) => void;
  index: number;
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const FormChipGroup = ({
  id,
  name,
  required,
  label,
  rules,
  control,
  register,
  errors,
  sx,
  chips,
  index,
  handleChipDelete,
  ...props
}: FormChipContainerProps): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box sx={sx} component="ul" {...field}>
          {chips &&
            chips.map((data: any) => (
              <ListItem key={data.key_id}>
                <Chip
                  label={`${data.process_name}-${data.ensemble_name}`}
                  onDelete={() => handleChipDelete(index, data.key_id)}
                />
              </ListItem>
            ))}
        </Box>
      )}
    />
  );
};

export default FormChipGroup;
