import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import { useTranslation } from "react-i18next"
import EditIcon from "@mui/icons-material/Edit"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { useCallback, useEffect, useState } from "react"
import FamilyService from "../../services/famiy.service"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import Divider from "@mui/material/Divider"
import DialogContent from "@mui/material/DialogContent"
import Box from "@mui/material/Box"
import { FormInput } from "../../components/FormInput"
import Grid from "@mui/material/Grid"
import DialogActions from "@mui/material/DialogActions"
import { useForm } from "react-hook-form"
import useLoader from "../../components/LoaderProvider"
import useNotifications from "../../components/NotificationProvider"

interface FormState {
  visible: boolean;
  heading: string;
}

interface ChildComponentProps {
  selectedEnv: any;
  // setSelectedEnv: any;
}

const Family = ({ selectedEnv }: ChildComponentProps) => {
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const { t } = useTranslation("common");
  const [tableRows, setTableRows] = useState<any[]>([])
  const [openFamilyForm, setOpenFamilyForm] = useState<FormState>({ visible: false, heading: '' })
  const { setLoading } = useLoader();
  const { setNotification } = useNotifications();


  const getFamilies = useCallback(async () => {
    try {
      const options = {
        headers: { "env-id": selectedEnv.environment_id }
      }
      const response = await FamilyService.getFamilies(options)
      const result = response.data.data
      setTableRows(result)
    } catch (e) {
      console.error("Error", e);
    } 
  },[selectedEnv.environment_id, ])
  
  useEffect(() => {
    try{
      if (selectedEnv) {
        setLoading(true)
        getFamilies();
      }
    } catch(e) {
      console.error("Error", e)
    } finally{
      setLoading(false)
    }
    


  }, [getFamilies, selectedEnv, setLoading])

  const handleOnClose = () => {
    setOpenFamilyForm({ ...openFamilyForm, visible: false });
  };

  const handleOpenFamilyFormClick = (visible: boolean, heading: string) => {
    setOpenFamilyForm({ visible, heading });
  }

  const handleEditClick = (family_id: any) => {
    const editRow = tableRows.find((row) => row.family_id === family_id)
    reset(
      {
        ...editRow
      })
  }

  const onSubmit = async (values: any) => {
    try {
      const options = {
        headers: { "env-id": selectedEnv.environment_id }
      }

      const family_id = values.family_id
      const reqBody = {
        name: values.name,
        description: values.description
      }

      if (family_id) {
        await FamilyService.updateFamily(family_id, reqBody, options)
      } else {
        await FamilyService.createFamily(reqBody, options)

      }

      setNotification({
        message: `Family ${family_id ? "Updated" : "Created"} successfully`,
        open: true,
      });

      setOpenFamilyForm({ visible: false, heading: '' })
      getFamilies();
      reset({});
    } catch (e) {
      console.error("Error",e)
      setNotification({ message: "Error", open: true, type: "error" });
    }
  }

  return (
    <>
      <Card sx={{ backgroundColor: "#2A2B31", maxWidth: "1550px" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <CardHeader
            action={[
              <Button
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  handleOpenFamilyFormClick(true, 'Create')
                  
                }}
                variant="contained"
                disabled={!selectedEnv}
                // disabled={selectedEnv && (selectedEnv.environment_id === '' || selectedEnv.environment_id === undefined) }
              >
                Create
              </Button>
            ]}
            title={t("common:ml_deployments.family.title")}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1050 }} aria-label="deployment table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Family</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Description</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Actions</TableCell>

                </TableRow>
                {tableRows.length > 0 ?
                  (
                    tableRows.map((row: any, index: Number) => (
                      <TableRow
                        key={row.family_id + index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231"
                          >
                            {row.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.description}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <EditIcon
                            sx={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={() => {
                              handleOpenFamilyFormClick(true, 'Edit')
                              handleEditClick(row.family_id)
                            }
                            }

                          />
                          {/* <DeleteIcon
                              sx={{ cursor: "pointer", marginRight: "10px" }}
                              
                            /> */}
                        </TableCell>
                      </TableRow>)
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No records found!</TableCell>
                    </TableRow>
                  )

                }
              </TableBody>
            </Table>

          </TableContainer>
        </CardContent>
        {/* Family Deployment creation dialog box */}
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openFamilyForm.visible}
          onClose={handleOnClose}
        >
          <DialogTitle>
            {`${openFamilyForm.heading}  Family Deployment`}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box component="main">
              <form>
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  pr={2}
                  pb={2}
                  sx={{ overflow: "auto" }}
                >

                  <Grid item xs={6}>
                    <FormInput
                      name="name"
                      id="name"
                      label="Family Name"
                      rules={{
                        required: "This field is required",
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormInput
                      name="description"
                      type="string"
                      id="description"
                      label="Description"
                      rules={{
                        required: "This field is required",
                      }}
                      control={control}
                      register={register}
                      errors={errors}
                    />

                  </Grid>


                </Grid>
              </form>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button sx={{ mr: 2 }} onClick={() => setOpenFamilyForm({ ...openFamilyForm, visible: false })}>
              Close
            </Button>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  )
}

export default Family