import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

type UserRole = string[];

interface UserContextProps {
  userRoles: UserRole | null; // Role can initially be null
  setUserRole: (userRoles: UserRole) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, isAuthenticated } = useAuth0(); // Get user info from Auth0
  const userData = user as any;
  const [userRoles, setUserRole] = useState<UserRole | null>(null); // Default to null while loading

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated) {
        try {
          // console.log("user", userData);
          // console.log(userData["https://controlrooms.ai/user"]["roles"]);
          setUserRole(userData["https://controlrooms.ai/user"]["roles"]);
        } catch (error) {
          console.error("Failed to fetch user userRoles:", error);
        }
      }
    };

    fetchUserRole();
  }, [isAuthenticated, userData]);

  return (
    <UserContext.Provider value={{ userRoles, setUserRole }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context)
    throw new Error("useUserContext must be used within a UserProvider");
  return context;
};
