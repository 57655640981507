import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DoneIcon from '@mui/icons-material/Done';
import { useCallback, useEffect, useState } from "react";
import { FormInput } from "../../components/FormInput";
import { useForm } from "react-hook-form";
import { FormCheckbox } from "../../components/FormCheckbox";
import { FormDatePicker } from "../../components/FormDatePicker";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import FamilyDeploymentService from "../../services/family-deployment.service";
import useLoader from "../../components/LoaderProvider";
import { FormAutocomplete } from "../../components/FormAutoComplete";
import { constructFamilyDeploymentDetail, convertUTCToLocalTime } from "../../utils/utils";
import useNotifications from "../../components/NotificationProvider";
import FamilyService from "../../services/famiy.service";

interface FormState {
  visible: boolean;
  heading: string;
}

interface ChildComponentProps {
    selectedEnv: any;
  }

const FamilyDeployments = ({selectedEnv}:ChildComponentProps) => {
  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,

    formState: { errors },
  } = useForm({});
  const { setLoading } = useLoader();
  const { t } = useTranslation("common");
  const [openFamilyDeploymentForm, setOpenFamilyDeploymentForm] = useState<FormState>({ visible: false, heading: '' })
  const [tableRows, setTableRows] = useState<any[]>([])
  const [families, setFamilies] = useState<any[]>([]);
  const [processes, setProcesses] = useState<any[]>([]);
  const { setNotification } = useNotifications();

  const onSubmit = async (values: any) => {
    try {

      const editMode = values.editMode
      const familyDeploymentDetail = constructFamilyDeploymentDetail(values, editMode)
      const options = {
        headers: { "env-id": selectedEnv.environment_id }
      }
      if (editMode) {
        const pathParams = {
          family_id: values.family_id.family_id,
          process_id: values.process_id.process_id,
          ensemble_id: values.ensemble_id
        }
        await FamilyDeploymentService.updateFamilyDeployment(pathParams, familyDeploymentDetail, options)
      } else {
        await FamilyDeploymentService.createFamilyDeployment(familyDeploymentDetail, options)
      }
      setNotification({
        message: `Family Deployment ${editMode ? "Updated" : "Created"} successfully`,
        open: true,
      });
      setOpenFamilyDeploymentForm({ visible: false, heading: '' })
      getFamilyDeployments();
      reset({});
    } catch (e) {
      console.error("Error", e);
			setNotification({ message: "Error", open: true, type: "error" });
    }
  };

  const handleEditClick = (uniqueId: string) => {
    const editRow = tableRows.find((row) => row.key === uniqueId)
    reset(
      {
        ...editRow,
        family_id: families.find(row => row.family_id === editRow.family_id),
        process_id: { ...processes.find(row => row.process_id === editRow.process_id)},
        start_time: convertUTCToLocalTime(editRow.start_time),
        end_time: editRow.end_time && convertUTCToLocalTime(editRow.end_time),
        editMode: true
      })
  }


  const handleOpenFamilyDeploymentFormClick = (visible: boolean, heading: string) => {
    setValue("editMode" , false)
    setOpenFamilyDeploymentForm({ visible, heading });
  }
  const handleOnClose = () => {
    setOpenFamilyDeploymentForm({ ...openFamilyDeploymentForm, visible: false });
  };



  const getFamilyDeployments = useCallback(async () => {
    try {
      const options = {
        headers: { "env-id": selectedEnv.environment_id }
      }
      const response = await FamilyDeploymentService.getFamilyDeployments(options)
      const result = response.data.data.map((data: any) => {
        return { ...data, key: `${data.family_id}-${data.process_id}-${data.ensemble_id}` };
      });
      setTableRows(result);
      
    } catch (e) {
      console.error("Error", e);
    }
  }, [selectedEnv?.environment_id])
  useEffect(() => {
    (async () => {
      try {
        const options = {
          headers: { "env-id": selectedEnv.environment_id }
        }
        setLoading(true)
				if (selectedEnv) {         
					getFamilyDeployments()
          const familiesResp = await FamilyService.getFamilies(options)
          const familyListResponse = familiesResp.data.data
          const newFamilyListResponse = familyListResponse.map((obj: any) => ({
            ...obj,
            family_name: obj.name
          }));
          setFamilies(newFamilyListResponse)
          const processResp = await FamilyDeploymentService.getProcesses(options)
          setProcesses(processResp.data.data)
        }

      } catch (e) {
        console.error("Error", e);
        setTableRows([])
        setFamilies([])
        setProcesses([])
      } finally {
        setLoading(false)
      }
    }

    )();

  }, [getFamilyDeployments, selectedEnv, setLoading]);

  return (
    <>
      
      <Card sx={{ backgroundColor: "#2A2B31", maxWidth: "1550px" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <CardHeader
            action={[
              <Button
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  handleOpenFamilyDeploymentFormClick(true, 'Create')
                }}
                variant="contained"
								disabled={!selectedEnv}
              >
                Create
              </Button>
            ]}
            title={t("common:ml_deployments.family_deployment.title")}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1050 }} aria-label="deployment table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Family</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Process</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Realtime</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Start Time</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>End Time</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Active</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Model URI</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Endpoint</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Ensemble</TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
                {tableRows.length > 0 ?
                  (
                    tableRows.map((row: any, index: Number) => (
                      <TableRow
                        key={row.key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231"
                          >
                            {row.family_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.process_name}
                          </Typography>
                        </TableCell>
                        <TableCell >
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.is_realtime ?
                              <DoneIcon></DoneIcon> :
                              <HorizontalRuleIcon></HorizontalRuleIcon>
                            }
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {convertUTCToLocalTime(row.start_time)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.end_time ? convertUTCToLocalTime(row.end_time): ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.is_active ?
                              <DoneIcon></DoneIcon> :
                              <HorizontalRuleIcon></HorizontalRuleIcon>}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.model_uri}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.endpoint_id}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            display="block"
                            color="#7AC231">
                            {row.ensemble_id}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <EditIcon
                            sx={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={() => {
                              handleOpenFamilyDeploymentFormClick(true, 'Edit')
                              handleEditClick(row.key)
                            }
                            }

                          />
                          {/* <DeleteIcon
                            sx={{ cursor: "pointer", marginRight: "10px" }}
                            
                          /> */}
                        </TableCell>
                      </TableRow>)
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No records found!</TableCell>
                    </TableRow>
                  )

                }
              </TableBody>
            </Table>

          </TableContainer>
        </CardContent>
      </Card>

      {/* Family Deployment creation dialog box */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openFamilyDeploymentForm.visible}
        onClose={handleOnClose}
      >
        <DialogTitle>
          {`${openFamilyDeploymentForm.heading}  Family Deployment`}
          {/* {openFamilyDeploymentForm.heading} */}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid
                container
                spacing={2}
                mt={1}
                pr={2}
                pb={2}
                sx={{ overflow: "auto" }}
              >
                <Grid item xs={4}>
                  <FormAutocomplete
                    name="family_id"
                    id="family_id"
                    label="Family"
                    options={families}
                    readOnly={getValues("editMode")}
                    labelKey="family_name"
                    valueKey="family_id"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormAutocomplete
                    name="process_id"
                    id="process_id"
                    label="Process"
                    readOnly={getValues("editMode")}
                    options={processes}
                    labelKey="process_name"
                    valueKey="process_id"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="ensemble_id"
                    id="ensemble_id"
                    label="Ensemble"
                    readOnly={getValues("editMode")}
                    rules={{
                      required: "This field is required",
                      validate: {
                        checkIsNumber: (v: string) => !isNaN(Number(v)) || "Ensemble ID must be a number"
                      }
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    name="endpoint_id"
                    type="string"
                    id="endpoint_id"
                    label="Endpoint"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />

                </Grid>


                <Grid item xs={4}>
                  <FormDatePicker
                    name="start_time"
                    id="start_time"
                    label="Start time"
                    type="datetime-local"
                    defaultValue={moment().format("yyyy-MM-DDTHH:mm")}
                    rules={{
                      required: "This field is required",
                      validate: {
                        greaterThanEndDate: (v: string) =>
                          !getValues("end_time") || moment(v).diff(getValues("end_time")) <= 0 ? true : "Start date can't be after End date",
                      },
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormDatePicker
                    name="end_time"
                    id="end_time"
                    label="End time"
                    type="datetime-local"
                    defaultValue={null}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name="is_realtime"
                    id="is_realtime"
                    label="Is Realtime"
                    rules={{}}
                    // defaultValue={true}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckbox
                    name="is_active"
                    id="is_active"
                    label="Is Active"
                    rules={{}}
                    defaultValue={true}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormInput
                    name="model_uri"
                    type="string"
                    id="model_uri"
                    label="Model URL"
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />

                </Grid>

              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenFamilyDeploymentForm({ ...openFamilyDeploymentForm, visible: false })}>
            Close
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FamilyDeployments;